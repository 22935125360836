import React, { ReactElement, useState, useEffect } from 'react'
import axios from 'axios'
import { HistoryLocation } from '@reach/router'
import useGlobalText from '@system/hooks/useGlobalText'

import MetaTags from '@components/modules/global/metatags'

import JobDetail from '@components/modules/content/M009-JobDetail'
import Contact from '@components/modules/content/M003-Contact'
import NotFound from '@components/modules/content/M014-NotFound'
import { Job as JobType } from '@/types/job'
import { useTheme } from '@mui/material/styles'

export interface JobPageProps {
  location: HistoryLocation
  pageContext: DBN.PageHelpers.PageContext
  params: {
    slug: string
  }
}

export default function Job({
  pageContext,
  params,
}: JobPageProps): ReactElement {
  const { getText } = useGlobalText()
  const [job, setJob] = useState<JobType>()
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    axios
      .get(
        `${process.env.GATSBY_JOB_SERVICE_URL}/api/jobs/${
          params.slug
        }?lang=${pageContext.locale.toUpperCase()}`
      )
      .then((res) => {
        const job = res.data
        setJob(job)
      })
      .catch((err) => {
        console.log(err)
      })
      .then(() => {
        setLoaded(true)
      })
  }, [])

  if (!job) return <NotFound pageContext={pageContext} theme={useTheme()} />

  return loaded ? (
    <>
      <MetaTags
        locale={pageContext.locale}
        meta={{
          noIndex: true,
          metaTitle: `${job.name} | Scholz & Friends`,
          metaDescription: pageContext.page.metaTags.metaDescription,
          metaOgtitle: `${job.name} | Scholz & Friends`,
          metaOgdescription: pageContext.page.metaTags.metaOgdescription,
          metaOgimage: pageContext.page.metaTags.metaOgimage,
        }}
      />

      <JobDetail
        pageContext={pageContext}
        moduleTheme="light"
        theme={useTheme()}
        name={job.name}
        slug={job.slug}
        jobId={job.jobId}
        unit={job.unit}
        office={job.office}
        department={job.department}
        schedule={job.schedule}
        type={job.type}
        jobDescriptions={job.jobDescriptions}
      />

      <Contact
        pageContext={pageContext}
        moduleTheme="light"
        theme={useTheme()}
        type="social"
        headline={getText('page.jobDetail.contact.headline')}
        socialNetwork={['LinkedIn', 'XING', 'Facebook', 'Twitter', 'E-Mail']}
      />
    </>
  ) : (
    <></>
  )
}
