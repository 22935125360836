import React, { ReactElement } from 'react'
import { graphql } from 'gatsby'
import { HistoryLocation } from '@reach/router'
import useGlobalText from '@system/hooks/useGlobalText'
import PressList, {
  PressPageRelated,
} from '@components/modules/content/M016-PressList'
import Copy from '@components/core/copy'
import { useTheme } from '@mui/material'

export interface PressPageProps {
  children?: typeof React.Children
  location: HistoryLocation
  pageContext: DBN.PageHelpers.PageContext
  data: {
    contentfulPressPage: {
      content: DBN.Contentful.BasicRichTextType
      modules: Array<DBN.Contentful.PageModuleUnion>
      related: Array<PressPageRelated>
    }
  }
}

export default function PressPage({
  data,
  location,
  pageContext,
}: PressPageProps): ReactElement {
  const content = data.contentfulPressPage.content
  const related = data.contentfulPressPage.related
  const { getText } = useGlobalText()

  return (
    <>
      {!!content && (
        <Copy
          richtext={content}
          location={location}
          pageContext={pageContext}
          type="container"
          theme="light"
        />
      )}
      <PressList
        pageContext={pageContext}
        theme={useTheme()}
        moduleTheme="light"
        headline={getText('press.related.headline')}
        related={related}
        count={3}
      />
    </>
  )
}

export const pageQuery = graphql`
  query PressPageByPath($id: String!, $locale: String!) {
    contentfulPressPage(id: { eq: $id }, node_locale: { eq: $locale }) {
      content {
        raw
        references {
          ...ContentfulRichTextFragment
        }
      }
      related {
        id
        teaserTitle
        releaseDate
        fields {
          fullPath
          isExternal
        }
      }
    }
  }
`
