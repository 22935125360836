import React, { ReactElement } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import parse from 'html-react-parser'
import clsx from 'clsx'
import Typography from '@mui/material/Typography'

const PREFIX = 'index'

const classes = {
  h2bg: `${PREFIX}-h2bg`,
}

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const StyledTypography = styled(Typography)(({ theme }) => ({
  [`&.${classes.h2bg}`]: {
    ...theme.typography.h2bg,
    [theme.breakpoints.up('md')]: {
      ...theme.typography.h2bg,
    },
  },
}))

export type HeadlineProps = DBN.IReactDefaultProps & {
  level?: number
  id?: string
  html?: string
}

export default function Headline({
  className,
  children,
  level,
  id,
  html,
  ...props
}: HeadlineProps): ReactElement {
  function renderChildren() {
    if (html) return null
    if (typeof children == 'string')
      return parse(
        children
          .replaceAll('  ', '<br>')
          .replaceAll('®', '<sup>®</sup>')
          .replaceAll('©', '<sup>©</sup>')
          .replaceAll('℗', '<sup>℗</sup>')
      )
    return children
  }
  function Level() {
    const Props = {
      id: id,
      ...(html && {
        dangerouslySetInnerHTML: {
          __html: html,
        },
      }),
      ...props,
    }
    switch (level) {
      case 21:
        return (
          <StyledTypography
            variant={'h2'}
            className={clsx(className, classes.h2bg)}
            {...Props}
            theme={useTheme()}
          >
            {renderChildren()}
          </StyledTypography>
        )
      case 1:
        return (
          <StyledTypography
            variant={'h1'}
            className={className}
            {...Props}
            theme={useTheme()}
          >
            {renderChildren()}
          </StyledTypography>
        )
      case 3:
        return (
          <StyledTypography
            variant={'h3'}
            className={className}
            {...Props}
            theme={useTheme()}
          >
            {renderChildren()}
          </StyledTypography>
        )
      case 4:
        return (
          <StyledTypography
            variant={'h4'}
            className={className}
            {...Props}
            theme={useTheme()}
          >
            {renderChildren()}
          </StyledTypography>
        )
      case 5:
        return (
          <StyledTypography
            variant={'h5'}
            className={className}
            {...Props}
            theme={useTheme()}
          >
            {renderChildren()}
          </StyledTypography>
        )
      default:
        return (
          <StyledTypography
            variant={'h2'}
            className={className}
            {...Props}
            theme={useTheme()}
          >
            {renderChildren()}
          </StyledTypography>
        )
    }
  }
  return <>{Level()}</>
}
