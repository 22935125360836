import React, { ReactElement } from 'react'
import { styled, Theme } from '@mui/material/styles'
import Module from '@components/core/module'

const PREFIX = 'index'

const classes = {
  vSpacerRoot: `${PREFIX}-vSpacerRoot`,
  desktop: `${PREFIX}-desktop`,
  mobile: `${PREFIX}-mobile`,
}

const StyledModule = styled(Module)(({ theme }) => ({
  [`&.${classes.vSpacerRoot}`]: {},

  [`& .${classes.desktop}`]: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },

  [`& .${classes.mobile}`]: {
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}))

export type VerticalSpacerProps = DBN.IReactDefaultProps & {
  titleInternal?: string
  theme?: Theme
  moduleTheme?: string
  sizeDesktop?: number
  sizeMobile?: number
}

export default function VerticalSpacer({
  theme,
  moduleTheme,
  sizeDesktop,
  sizeMobile,
}: VerticalSpacerProps): ReactElement {
  return (
    <StyledModule
      theme={theme}
      moduleTheme={moduleTheme}
      className={classes.vSpacerRoot}
    >
      {sizeDesktop && (
        <div
          className={classes.desktop}
          style={{ height: sizeDesktop + 'px' }}
        />
      )}
      {sizeMobile && (
        <div className={classes.mobile} style={{ height: sizeMobile + 'px' }} />
      )}
    </StyledModule>
  )
}
