import React, { ReactElement, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { navigate } from 'gatsby'
import { useLocation } from '@reach/router'
import { PaginationProps } from '@mui/material/Pagination'
import usePagination from '@mui/material/usePagination'
import clsx from 'clsx'
import Icon from '@components/core/icon'
import useCustomCursor from '@system/hooks/useCustomCursor'

const PREFIX = 'index'

const classes = {
  paginatorRoot: `${PREFIX}-paginatorRoot`,
  paginatorList: `${PREFIX}-paginatorList`,
  paginatorButton: `${PREFIX}-paginatorButton`,
  paginatorButtonActive: `${PREFIX}-paginatorButtonActive`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.paginatorRoot}`]: {},

  [`& .${classes.paginatorList}`]: {
    listStyle: 'none',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
    padding: 0,
  },

  [`& .${classes.paginatorButton}`]: {
    color: 'inherit',
    appearance: 'none',
    border: 'none',
    padding: 0,
    background: 'transparent',
    ...theme.typography.pagination,
    margin: theme.spacing(0, 2, 0, 2),
    paddingBottom: '2px',
    borderBottom: '2px solid transparent',
    minWidth: '30px',
    '&:disabled': {
      opacity: 0,
    },
  },

  [`& .${classes.paginatorButtonActive}`]: {
    borderColor: 'currentColor',
  },
}))

export type PaginatorProps = DBN.IReactDefaultProps & PaginationProps

export default function Paginator({
  className,
  page,
  onChange,
  ...props
}: PaginatorProps): ReactElement {
  const { setCursorType } = useCustomCursor()
  const location = useLocation()
  const [paramPage, setParamPage] = useState<number | null>(null)
  const { items } = usePagination({
    page: paramPage || page,
    onChange,
    ...props,
  })

  useEffect(() => {
    const query = new URLSearchParams(location.search)
    const queryPage = query.get('page')
    if (queryPage && parseInt(queryPage) !== paramPage) {
      setParamPage(parseInt(queryPage))
      if (onChange) onChange({} as React.ChangeEvent, parseInt(queryPage))
    }
  }, [location.search])

  return (
    <Root
      className={clsx(className, classes.paginatorRoot)}
      data-testid="pagination"
    >
      <ul className={classes.paginatorList}>
        {items.map(({ page, type, selected, onClick, ...item }, index) => {
          let children = null

          if (type === 'start-ellipsis' || type === 'end-ellipsis') {
            children = <span className={classes.paginatorButton}>…</span>
          } else if (type === 'page') {
            children = (
              <button
                type="button"
                className={clsx(classes.paginatorButton, {
                  [classes.paginatorButtonActive]: selected,
                })}
                onMouseEnter={() => setCursorType('link')}
                onMouseLeave={() => setCursorType('')}
                onClick={(ev) => {
                  onClick(ev)
                  navigate(`?page=${page}`)
                }}
                {...item}
              >
                {page}
              </button>
            )
          } else {
            children = (
              <button
                type="button"
                className={classes.paginatorButton}
                onMouseEnter={() => setCursorType('link')}
                onMouseLeave={() => setCursorType('')}
                {...item}
              >
                {type === 'previous' ? (
                  <Icon name="PaginationPrev" size="small" />
                ) : (
                  <Icon name="PaginationNext" size="small" />
                )}
              </button>
            )
          }

          return <li key={index}>{children}</li>
        })}
      </ul>
    </Root>
  )
}
