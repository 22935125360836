import React, { ReactElement, useState } from 'react'
import { styled, Theme } from '@mui/material/styles'
import clsx from 'clsx'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Module from '@components/core/module'
import Slider from '@components/core/slider'

const PREFIX = 'index'

const classes = {
  galleryRoot: `${PREFIX}-galleryRoot`,
  galleryImage: `${PREFIX}-galleryImage`,
  galleryImageZoom: `${PREFIX}-galleryImageZoom`,
  galleryImageAnimate: `${PREFIX}-galleryImageAnimate`,
}

const StyledModule = styled(Module)(() => ({
  [`&.${classes.galleryRoot}`]: {},

  [`& .${classes.galleryImage}`]: {
    overflow: 'hidden',
  },

  [`& .${classes.galleryImageZoom}`]: {
    display: 'block !important',
    transition: 'transform 0.6s ease-out',
  },

  [`& .${classes.galleryImageAnimate}`]: {
    [`& .${classes.galleryImageZoom}`]: {
      transform: 'scale(1.05)',
      transition: 'transform 0.6s ease-in',
    },
  },
}))

export type GalleryProps = DBN.IReactDefaultProps & {
  titleInternal?: string
  theme?: Theme
  moduleTheme?: string
  anchor?: string
  headline?: string
  images?: Array<DBN.Contentful.IAsset>
}

export default function Gallery({
  theme,
  moduleTheme,
  anchor,
  headline,
  images,
}: GalleryProps): ReactElement {
  const [animating, setAnimating] = useState(false)

  return (
    <StyledModule
      theme={theme}
      moduleTheme={moduleTheme}
      anchor={anchor}
      className={classes.galleryRoot}
    >
      <Slider
        headline={headline}
        onSlideNextTransitionStart={() => setAnimating(true)}
        onSlidePrevTransitionStart={() => setAnimating(true)}
        onSlideChangeTransitionEnd={() => setAnimating(false)}
      >
        {images &&
          images?.map((img, index) => (
            <div
              key={`gallery-item-${index}`}
              className={clsx(classes.galleryImage, {
                [classes.galleryImageAnimate]: animating,
              })}
            >
              {getImage(img) && (
                <GatsbyImage
                  image={getImage(img)}
                  alt={img?.description || ''}
                  title={img.title}
                  className={classes.galleryImageZoom}
                  layout="cover"
                />
              )}
            </div>
          ))}
      </Slider>
    </StyledModule>
  )
}
