import React from 'react'
import { styled, useTheme } from '@mui/material/styles'
import clsx from 'clsx'
import { TypeBackground } from '@mui/material/styles'
import { Theme } from '@mui/material'

const PREFIX = 'index'

const classes = {
  containerRoot: `${PREFIX}-containerRoot`,
  contentRoot: `${PREFIX}-contentRoot`,
  padded: `${PREFIX}-padded`,
  article: `${PREFIX}-article`,
  breadcrumb: `${PREFIX}-breadcrumb`,
  nomargin: `${PREFIX}-nomargin`,
  top: `${PREFIX}-top`,
  spaced: `${PREFIX}-spaced`,
}

const Root = styled('div')(({ theme, ...props }) => ({
  [`& .${classes.containerRoot}`]: {
    ...theme.mixins.containerSpacing,
    backgroundColor: props.background
      ? theme.palette.background[props.background]
      : undefined,
  },

  [`&.${classes.contentRoot}`]: {
    maxWidth: theme.container[props.width || 'lg'],
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(10),
      paddingRight: theme.spacing(10),
    },
  },

  [`& .${classes.padded}`]: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(20),
    marginTop: 0,
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(20),
      paddingBottom: theme.spacing(25),
    },
  },

  [`& .${classes.article}`]: {
    ...theme.mixins.article,
    marginBottom: 0,
  },

  [`& .${classes.breadcrumb}`]: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    marginBottom: 0,
  },

  [`& .${classes.nomargin}`]: {
    marginBottom: 0,
  },

  [`& .${classes.top}`]: {
    marginTop: theme.spacing(10),
  },

  [`& .${classes.spaced}`]: {
    margin: theme.spacing(5, 'auto'),
  },
}))

export type ContainerProps = DBN.IReactDefaultProps & {
  padded?: boolean
  type?: 'article' | 'breadcrumb' | 'nomargin'
  width?: keyof Theme['container']
  background?: keyof TypeBackground
  sidebar?: boolean
}

export default function Container({
  children,
  className,
  padded,
  type,
  width,
  background,
  sidebar,
  ...props
}: ContainerProps): React.ReactElement {
  function RenderInner() {
    if (background || padded) {
      return <div className={clsx(classes.contentRoot)}>{children}</div>
    } else {
      return <>{children}</>
    }
  }

  return (
    <Root
      className={clsx(className, classes.containerRoot, {
        [classes.contentRoot]: !padded && !background,
        [type ? classes[type] : '']: !!type,
      })}
      {...props}
      theme={useTheme()}
    >
      {RenderInner()}
    </Root>
  )
}
