import React, { ReactElement, useRef, useState, useEffect } from 'react'
import { styled, Theme } from '@mui/material/styles'
import clsx from 'clsx'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import Container from '@components/modules/global/container'
import Module from '@components/core/module'

const PREFIX = 'index'

const classes = {
  imageRoot: `${PREFIX}-imageRoot`,
  container: `${PREFIX}-container`,
  imageWrapper: `${PREFIX}-imageWrapper`,
  image: `${PREFIX}-image`,
}

const StyledModule = styled(Module)(({ theme }) => ({
  [`& .${classes.imageRoot}`]: {
    margin: 0,
    padding: 0,
  },

  [`& .${classes.container}`]: {
    padding: 0,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(10),
      paddingRight: theme.spacing(10),
    },
  },

  [`& .${classes.imageWrapper}`]: {
    overflow: 'hidden',
    width: '100%',
    height: '0',
    paddingBottom: 'calc(100% / 16 * 9)',
  },
}))

export type ImageProps = DBN.IReactDefaultProps & {
  titleInternal?: string
  theme?: Theme
  moduleTheme?: string
  anchor?: string
  image?: DBN.Contentful.IAsset
  caption?: string
  deactivateAnimation?: boolean
}

export default function Image({
  theme,
  moduleTheme,
  image,
  anchor,
  caption,
  deactivateAnimation,
}: ImageProps): ReactElement {
  const elementRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const windowH =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight

    function handleScroll() {
      const elementPositionY =
        windowH - (elementRef.current?.getBoundingClientRect().top || 0)
      const scrollArea = 2 * windowH
      if (elementPositionY > 0 && elementPositionY < scrollArea) {
        const sf = 1 + (elementPositionY / scrollArea) * 0.4
        elementRef.current?.style.setProperty('--scale', String(sf))
      }
    }
    if (!deactivateAnimation) window.addEventListener('scroll', handleScroll)
    return () => {
      if (!deactivateAnimation)
        window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <StyledModule theme={theme} moduleTheme={moduleTheme} anchor={anchor}>
      <div className={classes.imageRoot} ref={elementRef}>
        <Container type="nomargin" className={classes.container}>
          {image && (
            <div className={classes.imageWrapper}>
              <GatsbyImage
                image={getImage(image) as IGatsbyImageData}
                alt={image?.description || ''}
                title={image.title}
                layout="fullWidth"
                className={clsx(classes.image)}
                imgStyle={{
                  transform: `scale(var(--scale))`,
                  width: '100%',
                }}
              />
            </div>
          )}
          {caption && <div>{caption}</div>}
        </Container>
      </div>
    </StyledModule>
  )
}
