import React, { ReactElement, useRef, useState, useEffect } from 'react'
import { styled, Theme } from '@mui/material/styles'
import clsx from 'clsx'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Grid } from '@mui/material'
import { InView } from 'react-intersection-observer'

import Headline from '@components/text/headline'
import Container from '@components/modules/global/container'
import Copy from '@components/core/copy'
import Module from '@components/core/module'

const PREFIX = 'index'

const classes = {
  textImageRoot: `${PREFIX}-textImageRoot`,
  image: `${PREFIX}-image`,
  contentWrapper: `${PREFIX}-contentWrapper`,
  contentWrapperFadeIn: `${PREFIX}-contentWrapperFadeIn`,
  contentInner: `${PREFIX}-contentInner`,
  headline: `${PREFIX}-headline`,
  copy: `${PREFIX}-copy`,
  link: `${PREFIX}-link`,
}

const StyledModule = styled(Module)(({ theme }) => ({
  [`& .${classes.textImageRoot}`]: {
    paddingTop: theme.spacing(20),
    marginBottom: theme.spacing(12),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(17),
    },
    flexGrow: 1,
  },

  [`& .${classes.image}`]: {
    display: 'block !important',
    width: '100%',
    margin: 0,
    '& > div': {
      maxWidth: 'none !important',
    },
    [theme.breakpoints.up('sm')]: {
      '& > div': {
        maxWidth: '520px !important',
        paddingRight: theme.spacing(4),
        boxSizing: 'content-box',
      },
    },
  },

  [`& .${classes.contentWrapper}`]: {
    opacity: 0,
    transform: 'translate(0, 10vh)',
    transition: 'transform 0.6s ease-in, opacity 0.6s ',
    paddingTop: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(10),
    },
  },

  [`& .${classes.contentWrapperFadeIn}`]: {
    opacity: 1,
    transform: 'translate(0, 0)',
    transition: 'transform 0.6s ease-out, opacity 0.6s ',
  },

  [`& .${classes.contentInner}`]: {
    '& :last-child': {
      marginBottom: 0,
    },
  },

  [`& .${classes.headline}`]: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(0),
    },
  },

  [`& .${classes.copy}`]: {
    marginTop: theme.spacing(4),
  },

  [`& .${classes.link}`]: {
    marginTop: theme.spacing(6),
  },
}))

export type TextImageProps = DBN.IReactDefaultProps & {
  titleInternal?: string
  theme?: Theme
  moduleTheme?: string
  anchor?: string
  type?: string
  headline?: string
  copy?: DBN.Contentful.BasicRichTextType
  link?: DBN.Contentful.BasicRichTextType
  image?: DBN.Contentful.IAsset
}

export default function TextImage({
  theme,
  moduleTheme,
  anchor,
  type,
  headline,
  copy,
  link,
  image,
}: TextImageProps): ReactElement {
  const elementRef = useRef<HTMLDivElement>(null)
  const [scale, setScale] = useState(1)

  useEffect(() => {
    const windowH =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight

    function handleScroll() {
      const elementPositionY =
        windowH - (elementRef.current?.getBoundingClientRect().top || 0)
      const scrollArea = 2 * windowH
      if (elementPositionY > 0 && elementPositionY < scrollArea) {
        const sf = 1 + (elementPositionY / scrollArea) * 0.4
        setScale(sf)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  return (
    <StyledModule theme={theme} moduleTheme={moduleTheme} anchor={anchor}>
      <div
        className={clsx(classes.textImageRoot, {
          [classes.textImageNews]: type == 'news',
        })}
        ref={elementRef}
      >
        <Container type="nomargin">
          <Grid
            container
            justifyContent="center"
            alignItems="flex-start"
            spacing={8}
          >
            <Grid item xs={12} sm={6}>
              {image && getImage(image) && (
                <GatsbyImage
                  image={getImage(image)}
                  alt={image.description}
                  title={image.title}
                  imgStyle={{
                    objectFit: 'cover',
                    objectPosition: 'center',
                    transform: `scale(${scale})`,
                  }}
                  className={classes.image}
                />
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              <InView threshold={0} triggerOnce={true} delay={100}>
                {({ inView, ref }) => (
                  <div
                    className={clsx(classes.contentWrapper, {
                      [classes.contentWrapperFadeIn]: inView,
                    })}
                    ref={ref}
                  >
                    <div className={classes.contentInner}>
                      {headline && (
                        <Headline className={classes.headline} level={2}>
                          {headline}
                        </Headline>
                      )}
                      {copy && (
                        <Copy className={classes.copy} richtext={copy} />
                      )}
                      {type == 'news' && link && (
                        <Copy
                          className={classes.link}
                          richtext={link}
                          type="teaser"
                        />
                      )}
                    </div>
                  </div>
                )}
              </InView>
            </Grid>
          </Grid>
        </Container>
      </div>
    </StyledModule>
  )
}
