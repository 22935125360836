import React, { ReactElement, useState, useEffect } from 'react'
import { styled, Theme } from '@mui/material/styles'
import Module from '@components/core/module'
import useCustomCursor from '@system/hooks/useCustomCursor'
import useGlobalText from '@system/hooks/useGlobalText'
import Container from '@components/modules/global/container'
import { Grid } from '@mui/material'
import Copy from '@components/core/copy'
import Headline from '@components/text/headline'
import { Typography } from '@mui/material'
import FontSize from '@config/theme/definitions/fontSize'
import Icon from '@components/core/icon'
import Link from '@components/core/link'

const PREFIX = 'index'

const classes = {
  contactRoot: `${PREFIX}-contactRoot`,
  contactWrapper: `${PREFIX}-contactWrapper`,
  headline: `${PREFIX}-headline`,
  sublineWrapper: `${PREFIX}-sublineWrapper`,
  content: `${PREFIX}-content`,
  sublineLink: `${PREFIX}-sublineLink`,
  subline: `${PREFIX}-subline`,
  socialNav: `${PREFIX}-socialNav`,
  socialItem: `${PREFIX}-socialItem`,
  socialLink: `${PREFIX}-socialLink`,
  linkIcon: `${PREFIX}-linkIcon`,
  copy: `${PREFIX}-copy`,
}

const StyledModule = styled(Module)(({ theme }) => ({
  [`&.${classes.contactRoot}`]: {},

  [`& .${classes.contactWrapper}`]: {
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(12),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10),
    },
  },

  [`& .${classes.headline}`]: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
    },
  },

  [`& .${classes.sublineWrapper}`]: {
    display: 'block',
    marginBottom: theme.spacing(2),
    fontSize: FontSize['lg'],
  },

  [`& .${classes.content}`]: {
    margin: theme.spacing(2, 0, 0),
  },

  [`& .${classes.sublineLink}`]: {},

  [`& .${classes.subline}`]: {
    marginButtom: 0,
    fontSize: '18px',
  },

  [`& .${classes.socialNav}`]: {
    padding: 0,
    listStyle: 'none',
    margin: theme.spacing(3, 0),
  },

  [`& .${classes.socialItem}`]: {
    display: 'inline-block',
    marginRight: theme.spacing(10),
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(10),
    },
  },

  [`& .${classes.socialLink}`]: {
    position: 'relative',
    display: 'inline-block',
    '@media (hover: hover)': {
      '&::before': {
        content: '""',
        position: 'absolute',
        display: 'block',
        top: '50%',
        left: '50%',
        width: '48px',
        height: '48px',
        backgroundColor: '#FF2001',
        borderRadius: '50%',
        margin: '-24px',
        transform: 'scale(0)',
        transition: 'transform 0.3s',
      },
      '&:hover': {
        color: theme.palette.text.primary,
        '&::before': {
          transform: 'scale(1)',
        },
      },
    },
    '&>svg': {
      position: 'relative',
      zIndex: 1,
    },
  },

  [`& .${classes.linkIcon}`]: {
    display: 'inline-block',
    width: '16px',
    marginLeft: theme.spacing(2),
  },

  [`& .${classes.copy}`]: {
    '& :last-child': {
      marginBottom: 0,
    },
  },
}))

export type ContactProps = DBN.IReactDefaultProps & {
  pageContext?: DBN.PageHelpers.PageContext
  titleInternal?: string
  moduleTheme?: string
  theme?: Theme
  anchor?: string
  type?: string
  headline?: string
  sublineCopy?: DBN.Contentful.BasicRichTextType
  copy?: DBN.Contentful.BasicRichTextType
  linkInternal?: string
  linkExternal?: string
  email?: string
  socialNetwork?: Array<string>
}

export type IWithLink = {
  data?: { references: [{ fields: { fullPath: string; isExternal: boolean } }] }
  className?: string
  children?: React.ReactChild | React.ReactNode | string
}

function WithLink({ data, className, children }: IWithLink): ReactElement {
  return data &&
    data.references &&
    data.references[0] &&
    data.references[0].fields ? (
    <Link
      className={className}
      to={data.references[0].fields.fullPath}
      isExternal={data.references[0].fields.isExternal}
    >
      {children}
      <span className={classes.linkIcon}>
        <Icon name="LinkArrowRight" />
      </span>
    </Link>
  ) : (
    children
  )
}

export type ISocialIcon = {
  type: string
  className: string
  title: string
  shareURL: string
}

function SocialIcon({
  type,
  className,
  title,
  shareURL,
}: ISocialIcon): ReactElement {
  const { setCursorType } = useCustomCursor()
  const { getText } = useGlobalText()
  const emailText = getText('share.email.text')
    ? encodeURIComponent(getText('share.email.text').split('%5Cn').join('%0D'))
    : ''
  const twitterText = getText('share.email.text')
    ? encodeURIComponent(getText('share.twitter.text'))
    : ''
  const xing = `https://www.xing.com/social/share/spi?url=${shareURL}`
  const linkedIn = `https://www.linkedin.com/sharing/share-offsite/?url=${shareURL}&title=${encodeURIComponent(
    title
  )}`
  const facebook = `https://www.facebook.com/share.php?u=${shareURL}`
  const twitter = `https://twitter.com/intent/tweet?text=${twitterText}&url=${shareURL}`
  const email = `mailto:?subject=${encodeURIComponent(
    title
  )}&body=${emailText}${encodeURIComponent(shareURL)}`

  switch (type) {
    case 'XING':
      return (
        <a
          href={xing}
          rel="noopener noreferrer"
          target="_blank"
          className={className}
          onMouseEnter={() => setCursorType('hide')}
          onMouseLeave={() => setCursorType('')}
          onClick={() => setCursorType('')}
        >
          <Icon name="shareXING" />
        </a>
      )
    case 'LinkedIn':
      return (
        <a
          href={linkedIn}
          rel="noopener noreferrer"
          target="_blank"
          className={className}
          onMouseEnter={() => setCursorType('hide')}
          onMouseLeave={() => setCursorType('')}
          onClick={() => setCursorType('')}
        >
          <Icon name="shareLinkedIn" />
        </a>
      )
    case 'Facebook':
      return (
        <a
          href={facebook}
          rel="noopener noreferrer"
          target="_blank"
          className={className}
          onMouseEnter={() => setCursorType('hide')}
          onMouseLeave={() => setCursorType('')}
          onClick={() => setCursorType('')}
        >
          <Icon name="shareFacebook" />
        </a>
      )
    case 'Twitter':
      return (
        <a
          href={twitter}
          rel="noopener noreferrer"
          target="_blank"
          className={className}
          onMouseEnter={() => setCursorType('hide')}
          onMouseLeave={() => setCursorType('')}
          onClick={() => setCursorType('')}
        >
          <Icon name="shareTwitter" />
        </a>
      )
    case 'E-Mail':
      return (
        <a
          href={email}
          rel="noopener noreferrer"
          target="_blank"
          className={className}
          onMouseEnter={() => setCursorType('hide')}
          onMouseLeave={() => setCursorType('')}
          onClick={() => setCursorType('')}
        >
          <Icon name="shareEMail" />
        </a>
      )
  }
  return <></>
}

export default function Contact({
  pageContext,
  theme,
  moduleTheme,
  anchor,
  type,
  headline,
  sublineCopy,
  copy,
  socialNetwork,
}: ContactProps): ReactElement {
  const metaTitle =
    pageContext && pageContext.page && pageContext.page.title
      ? pageContext.page.title
      : 'sdsd'

  const [shareURL, setShareUrl] = useState('')

  useEffect(() => {
    setShareUrl(pageContext?.canonical || window.location.href)
  }, [setShareUrl])

  return (
    <StyledModule
      className={classes.contactRoot}
      theme={theme}
      moduleTheme={moduleTheme}
      anchor={anchor}
    >
      <div className={classes.contactWrapper}>
        <Container type="nomargin">
          <Grid
            container
            justifyContent="space-between"
            alignContent="flex-start"
            alignItems="flex-start"
            spacing={8}
          >
            <Grid item xs={12} md={6}>
              {headline && (
                <Headline level={2} className={classes.headline}>
                  {headline}
                </Headline>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              {type && type === 'default' && (
                <div className={classes.content}>
                  {sublineCopy && (
                    <Typography variant="h3" className={classes.sublineWrapper}>
                      <WithLink
                        data={sublineCopy}
                        className={classes.sublineLink}
                      >
                        <Copy
                          richtext={sublineCopy}
                          type="simple"
                          className={classes.subline}
                        />
                      </WithLink>
                    </Typography>
                  )}
                  {copy && <Copy richtext={copy} className={classes.copy} />}
                </div>
              )}
              {type && type === 'social' && (
                <ul className={classes.socialNav}>
                  {socialNetwork?.map((page: string, j: number) => (
                    <li className={classes.socialItem} key={j}>
                      <SocialIcon
                        className={classes.socialLink}
                        type={page}
                        title={metaTitle}
                        shareURL={shareURL}
                      />
                    </li>
                  ))}
                </ul>
              )}
            </Grid>
          </Grid>
        </Container>
      </div>
    </StyledModule>
  )
}
