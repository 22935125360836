import React, { ReactElement } from 'react'
import { styled, Theme } from '@mui/material/styles'
import clsx from 'clsx'
import { InView } from 'react-intersection-observer'
import { Grid } from '@mui/material'
import Container from '@components/modules/global/container'
import Copy from '@components/core/copy'
import Headline from '@components/text/headline'
import Paragraph from '@components/text/paragraph'
import Module from '@components/core/module'

const PREFIX = 'index'

const classes = {
  textRoot: `${PREFIX}-textRoot`,
  contentWrapper: `${PREFIX}-contentWrapper`,
  contentWrapperFadeIn: `${PREFIX}-contentWrapperFadeIn`,
  tagline: `${PREFIX}-tagline`,
  headline: `${PREFIX}-headline`,
  headline2: `${PREFIX}-headline2`,
  subline: `${PREFIX}-subline`,
  copyContainer: `${PREFIX}-copyContainer`,
  copy: `${PREFIX}-copy`,
  copyColumns: `${PREFIX}-copyColumns`,
}

const StyledModule = styled(Module)(({ theme }) => ({
  [`&.${classes.textRoot}`]: {
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(20),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(30),
      paddingBottom: theme.spacing(30),
    },
  },

  [`& .${classes.contentWrapper}`]: {
    marginBottom: 0,
    opacity: 0,
    transform: 'translate(0, 10vh)',
    transition: 'transform 0.6s ease-in, opacity 0.6s',
  },

  [`& .${classes.contentWrapperFadeIn}`]: {
    transform: 'translate(0, 0)',
    opacity: 1,
    transition: 'transform 0.6s ease-out, opacity 0.6s',
  },

  [`& .${classes.tagline}`]: {
    marginBottom: 0,
    paddingBottom: theme.spacing(4),
    ...theme.typography.introtext2,
    color: theme.palette.text.hint,
  },

  [`& .${classes.headline}`]: {
    marginBottom: 0,
  },

  [`& .${classes.headline2}`]: {
    marginBottom: 0,
    paddingBottom: theme.spacing(4),
  },

  [`& .${classes.subline}`]: {
    marginBottom: 0,
    ...theme.typography.subtitle1,
    paddingTop: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(8),
    },
  },

  [`& .${classes.copyContainer}`]: {
    marginTop: 0,
    marginBottom: 0,
    paddingTop: theme.spacing(4),

    '& .MuiGrid-item': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },

  [`& .${classes.copy}`]: {
    marginTop: 0,
    marginBottom: 0,
    paddingTop: 0,
    paddingBottom: 0,
    '& :last-child': {
      marginBottom: 0,
    },
  },

  [`& .${classes.copyColumns}`]: {
    [theme.breakpoints.up('md')]: {
      columns: '2',
      columnGap: theme.spacing(8),
    },
  },
}))

export type TextProps = DBN.IReactDefaultProps & {
  titleInternal?: string
  theme?: Theme
  moduleTheme?: string
  layout?: string
  anchor?: string
  tagline?: string
  headline?: string
  headline2?: string
  subline?: string
  copy?: DBN.Contentful.BasicRichTextType
}

export default function Text({
  theme,
  moduleTheme,
  anchor,
  layout = 'full',
  tagline,
  headline,
  headline2,
  subline,
  copy,
}: TextProps): ReactElement {
  return (
    <InView threshold={0} triggerOnce={true} delay={100}>
      {({ inView, ref }) => (
        <StyledModule
          theme={theme}
          moduleTheme={moduleTheme}
          anchor={anchor}
          className={classes.textRoot}
        >
          <div ref={ref}>
            <Container
              type="nomargin"
              className={clsx(classes.contentWrapper, {
                [classes.contentWrapperFadeIn]: inView,
              })}
            >
              {tagline && (
                <Paragraph className={classes.tagline}>{tagline}</Paragraph>
              )}
              {headline && (
                <Headline level={1} className={classes.headline}>
                  {headline}
                </Headline>
              )}
              {headline2 && (
                <Headline level={21} className={classes.headline2}>
                  {headline2}
                </Headline>
              )}
              {subline && (
                <Paragraph className={classes.subline}>{subline}</Paragraph>
              )}

              {copy && (
                <Grid container spacing={8} className={classes.copyContainer}>
                  <Grid item xs={12} md={layout == 'half' ? 6 : 12}>
                    <Copy
                      className={clsx(classes.copy, {
                        [classes.copyColumns]: layout == 'columns',
                      })}
                      richtext={copy}
                    />
                  </Grid>
                </Grid>
              )}
            </Container>
          </div>
        </StyledModule>
      )}
    </InView>
  )
}
