import React, {
  ReactElement,
  useState,
  useEffect,
  useRef,
  useReducer,
} from 'react'
import { styled, Theme } from '@mui/material/styles'
import Headline from '@components/text/headline'
import BackgroundMedia from '@components/media/backgroundMedia'
import clsx from 'clsx'
import Module from '@components/core/module'
import useCustomCursor from '@system/hooks/useCustomCursor'
import Icon from '@components/core/icon'

const PREFIX = 'default'

const classes = {
  stageRoot: `${PREFIX}-stageRoot`,
  work: `${PREFIX}-work`,
  stageRootInner: `${PREFIX}-stageRootInner`,
  stageImage: `${PREFIX}-stageImage`,
  overlay: `${PREFIX}-overlay`,
  contentWrapper: `${PREFIX}-contentWrapper`,
  contentWrapperDefault: `${PREFIX}-contentWrapperDefault`,
  contentInner: `${PREFIX}-contentInner`,
  contentInnerCopy: `${PREFIX}-contentInnerCopy`,
  headline: `${PREFIX}-headline`,
  scrollDownArrow: `${PREFIX}-scrollDownArrow`,
}

const StyledModule = styled(Module)(({ theme }) => ({
  [`&.${classes.stageRoot}`]: {
    overflow: 'hidden',
    backgroundColor: theme.palette.common.black,
    '&:hover .MuiSvgIcon-root': {
      opacity: 1,
    },
  },

  [`&.${classes.work}`]: {
    overflow: 'visible',
    marginBottom: '-10vh',
    // marginBottom: 'calc(-100vh + 264px)',
    // [theme.breakpoints.up('md')]: {
    //   marginBottom: 'calc(-100vh + 470px)',
    // },
  },

  [`& .${classes.stageRootInner}`]: {
    width: '100vw',
    height: '100vh',
    position: 'relative',
    overflow: 'hidden',
  },

  [`& .${classes.stageImage}`]: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    minWidth: '100%',
    minHeight: '100%',
    margin: '0 !important',
    zIndex: 0,
    '& .gatsby-image-wrapper': {
      height: '100vh',
    },
    [theme.breakpoints.up('md')]: {
      '& .gatsby-image-wrapper': {
        height: '100vh',
      },
    },
  },

  [`& .${classes.overlay}`]: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '0px',
    background: 'linear-gradient(0deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0) 100%)',
    backgroundColor: 'rgba(0,0,0,0.2)',
    zIndex: 1,
  },

  [`& .${classes.contentWrapper}`]: {
    position: 'absolute',
    color: theme.palette.text.invert,
    zIndex: 2,
    top: theme.spacing(14),
    [theme.breakpoints.up('md')]: {
      top: 0,
      width: '100%',
      height: '100%',
      minHeight: '224px',
    },
  },

  [`& .${classes.contentWrapperDefault}`]: {
    top: 0,
    width: '100%',
    height: '100%',
    minHeight: '224px',
  },

  [`& .${classes.contentInner}`]: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(4),
    paddingTop: theme.spacing(8),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(10),
      maxWidth: theme.container.lg,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    '& h1, & h2': {
      display: 'block',
      marginBottom: '0px',
    },
  },

  [`& .${classes.contentInnerCopy}`]: {
    '@supports (-webkit-touch-callout: none)': {
      transform: 'translateY(-40px)',
    },
  },

  [`& .${classes.headline}`]: {
    letterSpacing: 0,
  },

  [`& .${classes.scrollDownArrow}`]: {
    position: 'absolute',
    zIndex: 4,
    opacity: 1,
    transition: 'opacity .5s linear',
    left: theme.spacing(4),
    bottom: theme.spacing(4),
    color: '#ffffff',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}))

export type DefaultStageProps = DBN.IReactDefaultProps & {
  theme?: Theme
  anchor?: string
  type?: string
  headline?: string
  headline2?: string
  media?: DBN.Contentful.IAsset
  mediaMobile?: DBN.Contentful.IAsset
  fallbackImage?: DBN.Contentful.IAsset
}

export default function DefaultStageVariant({
  theme,
  anchor,
  type,
  headline,
  headline2,
  media,
  mediaMobile,
  fallbackImage,
}: DefaultStageProps): ReactElement {
  const [offset, setOffset] = useState(0)
  const [windowW, setWindowW] = useState(1024)
  const [windowH, setWindowH] = useState(768)
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0)
  const { setCursorType } = useCustomCursor()
  const damping = 0.3
  const headlineContainer = useRef<HTMLDivElement>(null)
  const hitbox = useRef<HTMLDivElement>(null)
  const [isTop, setIsTop] = useState(true)
  const [hasHover, setHasHover] = useState(true)

  const easeInOutQuint = (t: number) => {
    return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t
  }

  useEffect(() => {
    const mediaQuery = window.matchMedia('(hover: hover)')

    const mediaHandler = (event: MediaQueryListEvent): void => {
      if (event.matches) {
        setHasHover(true)
      } else {
        setHasHover(false)
      }
    }

    setHasHover(mediaQuery.matches)

    const handleResize = () => {
      const wW =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth

      setWindowW(wW)

      const wH =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight

      forceUpdate()
      setWindowH(wH)
    }

    handleResize()

    const requestAnimationFrame =
      window.requestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      window.oRequestAnimationFrame ||
      window.msRequestAnimationFrame

    function scrollTo(to: number) {
      const start = window.scrollY || window.pageYOffset
      const time = Date.now()
      const duration = 1500

      ;(function step() {
        const dx = Math.min(1, (Date.now() - time) / duration)
        const pos = start + (to - start) * easeInOutQuint(dx)
        window.scrollTo(0, pos)
        if (dx < 1) {
          requestAnimationFrame(step)
        }
      })()
    }

    function handleScroll() {
      if (window.pageYOffset < windowH) {
        setOffset(window.pageYOffset)
      }
      setIsTop(window.pageYOffset <= 30)
    }

    if (hitbox.current) {
      hitbox.current.addEventListener('click', () => {
        const wH =
          window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight
        scrollTo(wH)
      })
    }

    window.addEventListener('scroll', handleScroll)
    window.addEventListener('resize', handleResize)
    mediaQuery.addEventListener('change', mediaHandler)
    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', handleResize)
      mediaQuery.removeEventListener('change', mediaHandler)
    }
  }, [])

  const isVideo = media?.file?.contentType.startsWith('video') // #endregion

  const SPACE = windowW > 768 ? 105 : 33
  const hPosY = Math.max(headlineContainer.current?.offsetHeight || 150, 60)
  let startPoint = (windowH * 0.5 + (hPosY * 0.5 + SPACE)) / windowH

  if (windowW <= 768) {
    startPoint = (90 + hPosY + SPACE) / windowH
  }
  const startPointPercent = Math.round(startPoint * 100)

  return (
    <StyledModule
      className={clsx(classes.stageRoot, {
        [classes.work]: type === 'work',
      })}
      theme={theme}
      moduleTheme="dark"
      anchor={anchor}
      style={
        type === 'work'
          ? { marginBottom: `-${100 - startPointPercent}vh` }
          : null
      }
    >
      <div
        className={classes.stageRootInner}
        onMouseEnter={() => setCursorType('arrow-down')}
        onMouseLeave={() => setCursorType('')}
        style={{
          transform: `translateY(${offset * damping}px)`,
          height: '100dvh',
        }}
        ref={hitbox}
      >
        {media && (
          <BackgroundMedia
            className={classes.stageImage}
            media={media}
            mediaMobile={mediaMobile}
            fallbackImage={fallbackImage}
          />
        )}

        {isVideo && <div className={classes.overlay} />}

        <div
          className={clsx(classes.contentWrapper, {
            [classes.contentWrapperDefault]: type !== 'work',
          })}
        >
          <div className={classes.contentInner}>
            <div ref={headlineContainer} className={classes.contentInnerCopy}>
              {headline && (
                <Headline level={1} className={classes.headline}>
                  {headline}
                </Headline>
              )}
              {headline2 && (
                <Headline level={2} className={classes.headline}>
                  {headline2}
                </Headline>
              )}
            </div>
          </div>
        </div>
        {!hasHover && type && type !== 'work' && (
          <div
            className={classes.scrollDownArrow}
            style={{ opacity: isTop ? 1 : 0 }}
          >
            <Icon name="ArrowDown" />
          </div>
        )}
      </div>
    </StyledModule>
  )
}
