import React, { ReactElement } from 'react'
import { styled, Theme } from '@mui/material/styles'
import Container from '@components/modules/global/container'
import Module from '@components/core/module'
import { default as HeadlineObject } from '@components/text/headline'

const PREFIX = 'index'

const classes = {
  headlineRoot: `${PREFIX}-headlineRoot`,
  headlineHeadline: `${PREFIX}-headlineHeadline`,
}

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const StyledModule = styled(Module)(({ theme }) => ({
  [`&.${classes.headlineRoot}`]: {
    paddingTop: theme.spacing(30),
    paddingBottom: theme.spacing(12),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(48),
      paddingBottom: theme.spacing(12),
    },
  },

  [`& .${classes.headlineHeadline}`]: {
    margin: 0,
  },
}))

export type HeadlineProps = DBN.IReactDefaultProps & {
  theme?: Theme
  moduleTheme?: string
  anchor?: string
  headline?: string
}

export default function Headline({
  theme,
  moduleTheme,
  anchor,
  headline,
}: HeadlineProps): ReactElement {
  return headline ? (
    <StyledModule
      theme={theme}
      moduleTheme={moduleTheme}
      anchor={anchor}
      className={classes.headlineRoot}
    >
      <Container type="nomargin">
        <HeadlineObject level={1} className={classes.headlineHeadline}>
          {headline}
        </HeadlineObject>
      </Container>
    </StyledModule>
  ) : (
    <></>
  )
}
