import React, { ReactElement } from 'react'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import { Snackbar, Typography, Button } from '@mui/material'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import Container from '@components/modules/global/container'
import Icon from '@components/core/icon'
import FontSize from '@config/theme/definitions/fontSize'

const PREFIX = 'index'

const classes = {
  notificationRoot: `${PREFIX}-notificationRoot`,
  notificationBar: `${PREFIX}-notificationBar`,
  notificationContentWrapper: `${PREFIX}-notificationContentWrapper`,
  notificationContent: `${PREFIX}-notificationContent`,
  copy: `${PREFIX}-copy`,
  closeButton: `${PREFIX}-closeButton`,
  link: `${PREFIX}-link`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.notificationRoot}`]: {
    [theme.breakpoints.up('md')]: {},
  },

  [`& .${classes.notificationBar}`]: {
    width: '100vw',
    top: 0,
    left: 0,
    position: 'fixed',
    transform: 'none',
    display: 'block',
    background: '#0000DB',
  },

  [`& .${classes.notificationContentWrapper}`]: {},

  [`& .${classes.notificationContent}`]: {
    display: 'flex',
    justifyContent: 'space-between',

    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
    },
  },

  [`& .${classes.copy}`]: {
    fontSize: FontSize['lg'],
    lineHeight: FontSize['xl'],
  },

  [`& .${classes.closeButton}`]: {
    paddingLeft: '80px',
    display: 'block',
    '&svg': {
      width: '24px',
      height: '24px',
    },
  },

  [`& .${classes.link}`]: {},
}))

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

export type INotificationProps = {
  show?: boolean
  children?: React.ReactChild | React.ReactNode | string
  onClose?: (event: React.ChangeEvent<any>, value: number | number[]) => void
}

export default function Notification({
  show,
  children,
  onClose,
}: INotificationProps): ReactElement {
  return (
    <Root className={clsx(classes.notificationRoot)}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={show}
        autoHideDuration={6000}
        onClose={onClose}
        className={classes.notificationBar}
      >
        <div>
          <Container
            type="nomargin"
            className={classes.notificationContentWrapper}
          >
            <div className={classes.notificationContent}>
              <Typography
                className={classes.copy}
                variant="body1"
                component="p"
              >
                {children}
              </Typography>
              <a className={classes.closeButton} onClick={onClose}>
                <Icon name="Close" color="inherit" />
              </a>
            </div>
          </Container>
        </div>
      </Snackbar>
    </Root>
  )
}
