import React, { ReactElement, useEffect, useState, useRef } from 'react'
import useCustomCursor from '@system/hooks/useCustomCursor'
import clsx from 'clsx'
import Headline from '@components/text/headline'
import Module from '@components/core/module'
import { InView } from 'react-intersection-observer'
import Icon from '@components/core/icon'
import FontSize from '@config/theme/definitions/fontSize'
import { Link } from 'gatsby'
import More from '../../../core/customCursor/icons/More'
import { styled, Theme } from '@mui/material/styles'
import { Box } from '@mui/material'

const PREFIX = 'index'

const classes = {
  servicesRoot: `${PREFIX}-servicesRoot`,
  svg: `${PREFIX}-svg`,
  servicesContainer: `${PREFIX}-servicesContainer`,
  serviceContainer: `${PREFIX}-serviceContainer`,
  servicesHeadline: `${PREFIX}-servicesHeadline`,
  logoContainer: `${PREFIX}-logoContainer`,
  logoShared: `${PREFIX}-logoShared`,
  logoOuterBackground: `${PREFIX}-logoOuterBackground`,
  logoOuter: `${PREFIX}-logoOuter`,
  logoInner: `${PREFIX}-logoInner`,
  serviceDisplay: `${PREFIX}-serviceDisplay`,
  serviceDisplayItem: `${PREFIX}-serviceDisplayItem`,
  serviceDisplayLink: `${PREFIX}-serviceDisplayLink`,
  mobileFakeCursor: `${PREFIX}-mobileFakeCursor`,
}

const StyledModule = styled(Module)(
  ({
    theme,
    elementAmount,
    previousModuleColor,
    nextModuleColor,
  }: {
    theme: Theme
    elementAmount: number
    previousModuleColor?: string
    nextModuleColor?: string
  }) => ({
    [`&.${classes.servicesRoot}`]: {
      background: `linear-gradient(to bottom, ${
        previousModuleColor || '#FFFFFF'
      } 50%, ${nextModuleColor || '#FFFFFF'} 50%)`,
      '--scaleX': '1',
      paddingTop: theme.spacing(20),
      paddingBottom: theme.spacing(20),
      '&::before': {
        content: '" "',
        height: `calc(${theme.spacing(20)} + 1px)`,
        top: 0,
        bottom: 'unset',
        width: '100%',
        minWidth: '600px',
        left: '50%',
        transform: 'translateX(-50%) scale(var(--scaleX), 1)',
        // clipPath: "path('M0,1 C0.2,0,0.8,0,1,1')",
        clipPath: 'url(#my-clip-path)',
      },
      '&::after': {
        content: '" "',
        position: 'absolute',
        height: `calc(${theme.spacing(20)} + 1px)`,
        top: 'unset',
        bottom: 0,
        width: '100%',
        minWidth: '600px',
        left: '50%',
        // clipPath: "path('M0,1 C0.2,0,0.8,0,1,1')",
        clipPath: 'url(#my-clip-path)',
        transform: 'translateX(-50%) rotate(0.5turn) scale(var(--scaleX), 1)',
      },
    },

    [`& .${classes.svg}`]: {
      position: 'absolute',
    },

    [`& .${classes.servicesContainer}`]: {
      backgroundColor: 'var(--background-color)',
      width: '100vw',
      height: `${75 * elementAmount}vh`,
    },

    [`& .${classes.serviceContainer}`]: {
      '--circle-size': 'min(min(72vh, 120vw), 520px)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100vh',
      width: '100%',
      paddingTop: theme.spacing(11.5),
      paddingBottom: theme.spacing(11.5),
      gap: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(4),
      },
    },

    [`& .${classes.servicesHeadline}`]: {
      textAlign: 'center',
    },

    [`& .${classes.logoContainer}`]: {
      position: 'relative',
      transition: 'transform 0.5s',
      flex: '0 0 var(--circle-size)',
      [theme.breakpoints.down('md')]: {
        '&:has(svg.active)': {
          transform: 'scale(0.8)',
        },
      },
    },

    [`& .${classes.logoShared}`]: {
      position: 'absolute',
      height: 'var(--circle-size)',
      width: 'var(--circle-size)',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },

    [`& .${classes.logoOuterBackground}`]: {
      color: theme.palette.secondary.dark,
    },

    [`& .${classes.logoOuter}`]: {
      clipPath:
        'polygon(50% 50%, 50% -100%, 50% -100%, 50% -100%, 50% -100%, 50% -100%)',
    },

    [`& .${classes.logoInner}`]: {
      opacity: 0,
      transform: 'translate(-50%, -50%) scale(0.6)',
      transition:
        'transform 0.35s cubic-bezier(0.34, 1.56, 0.64, 1) 0.2s, opacity 0.35s cubic-bezier(0.34, 1.56, 0.64, 1) 0.2s',
      '&.active': {
        opacity: 1,
        transform: 'translate(-50%, -50%) scale(1)',
      },
    },

    [`& .${classes.serviceDisplay}`]: {
      position: 'absolute',
      height: 'calc(var(--circle-size) * 0.7)',
      width: 'calc(var(--circle-size) * 0.7)',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },

    [`& .${classes.serviceDisplayItem}`]: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: '100%',
      textAlign: 'center',
      transform: 'translate(-50%, -50%) scale(0.9)',
      opacity: 0,
      fontSize: FontSize['5xl'],
      fontWeight: 700,
      transition: 'opacity 0.2s ease-in-out, transform 0.2s ease-in-out',
      '&.active': {
        opacity: 1,
        transform: 'translate(-50%, -50%) scale(1)',
        transition:
          'opacity 0.2s ease-in-out 0.2s, transform 0.2s ease-in-out 0.2s',
      },
    },

    [`& .${classes.serviceDisplayLink}`]: {
      display: 'none',
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: '100vw',
      height: '100vh',
      transform: 'translate(-50%, -50%)',
      '&.active': {
        display: 'block',
      },
    },

    [`& .${classes.mobileFakeCursor}`]: {
      visibility: 'hidden',
      position: 'absolute',
      width: '0px',
      height: '0px',
      backgroundColor: '#FF2001',
      color: '#000021',
      border: '0px solid #FF2001',
      boxSizing: 'border-box',
      borderRadius: '100%',
      transform: 'translate(-50%, 50%)',
      bottom: 0,
      left: '50%',
      transition: 'width 0.3s ease-out, height 0.3s ease-out',
      willChange: 'width, height, transform, border',
      zIndex: 999999,
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&.teaserMore': {
        width: '72px',
        height: '72px',
        border: '2px solid #FF2001',
      },
      '@media not (hover: hover)': {
        visibility: 'unset',
      },
    },
  }),
)

type DynamicStyles = {
  elementAmount: number
  servicesContainerIntersects: { top: boolean; bottom: boolean }
  previousModuleColor?: string
  nextModuleColor?: string
}

export type ServicesProps = DBN.IReactDefaultProps & {
  titleInternal?: string
  headline?: string
  theme: Theme
  moduleTheme?: string
  anchor?: string
  serviceElement?: Array<DBN.Contentful.IServiceElement>
  previousModuleColor?: string
  nextModuleColor?: string
}

export default function Services({
  headline,
  theme,
  moduleTheme,
  anchor,
  serviceElement,
  previousModuleColor,
  nextModuleColor,
}: ServicesProps): ReactElement {
  const [servicesContainerIntersects, setServicesContainerIntersects] =
    useState({ top: false, bottom: false })

  const containerRef = useRef<HTMLDivElement>(null)
  const logoOuterRef = useRef<SVGElement>(null)
  const mobileFakeCursorRef = useRef<HTMLDivElement>(null)

  const [activeItem, setActiveItem] = useState(0)

  const { setCursorType } = useCustomCursor()

  useEffect(() => {
    function handleScroll() {
      const modCnt = serviceElement ? serviceElement.length + 1 : 0
      if (modCnt === 0) {
        setActiveItem(0)
        return
      }

      if (containerRef.current) {
        const containerRect = containerRef.current?.getBoundingClientRect()

        let animateLogoClipPath = true

        const start = containerRect.top
        const stop = containerRect.bottom - window.innerHeight
        if (start < 0 && stop > 0) {
          const totalDistance = containerRect.height - window.innerHeight
          const position = -containerRect.top
          const distance = totalDistance / modCnt
          const va = Math.floor(position / distance)
          const index = Math.max(Math.min(va, modCnt - 1), 0)
          setActiveItem(index)
          const radians = Math.min(
            (((position / totalDistance) * Math.PI * 2) / (modCnt - 1)) *
              modCnt,
            Math.PI * 2,
          )
          const currentClipPoint = `${100 * Math.sin(radians) + 50}% ${
            -(100 * Math.cos(radians)) + 50
          }%`
          animateLogoClipPath = true
          if (logoOuterRef.current)
            logoOuterRef.current.style.clipPath = `polygon(50% 50%, 50% -100%, ${
              radians < Math.PI * 0.5 ? currentClipPoint : '150% 50%'
            }, ${radians < Math.PI ? currentClipPoint : '50% 150%'}, ${
              radians < Math.PI * 1.5 ? currentClipPoint : '-50% 50%'
            },  ${currentClipPoint})`
        } else if (start > 0 && start < window.innerHeight / 2) {
          const distance = -((1.5 / window.innerHeight) * 2 * start) + 1.5
          anchor &&
            document
              .getElementById(anchor)!
              .style.setProperty('--scaleX', String(distance * distance + 1))

          if (animateLogoClipPath) {
            animateLogoClipPath = false
            if (logoOuterRef.current)
              logoOuterRef.current.style.clipPath =
                'polygon(50% 50%, 50% -100%, 50% -100%, 50% -100%, 50% -100%, 50% -100%)'
          }
        } else if (stop < 0 && -stop < window.innerHeight / 2) {
          const distance = -((1.5 / window.innerHeight) * 2 * -stop) + 1.5
          anchor &&
            document
              .getElementById(anchor)!
              .style.setProperty('--scaleX', String(distance * distance + 1))
        }
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  if (
    !(Number(mobileFakeCursorRef.current?.dataset.previousIndex) === activeItem)
  ) {
    mobileFakeCursorRef.current?.classList.remove('teaserMore')
    typeof window !== 'undefined' &&
      window.setTimeout(() => {
        if (activeItem < serviceElement!.length)
          mobileFakeCursorRef.current?.classList.add('teaserMore')
      }, 200)
  }
  return (
    <StyledModule
      theme={theme}
      moduleTheme={moduleTheme}
      anchor={anchor}
      className={classes.servicesRoot}
      elementAmount={serviceElement ? serviceElement.length + 1 : 1}
      previousModuleColor={previousModuleColor}
      nextModuleColor={nextModuleColor}
    >
      <svg className={classes.svg} width="0" height="0">
        <clipPath id="my-clip-path" clipPathUnits="objectBoundingBox">
          <path d="M0,1 C0.3,-0.333,0.7,-0.333,1,1"></path>
        </clipPath>
      </svg>
      <InView
        rootMargin="0% 0% -100% 0%"
        as="div"
        onChange={(inView, entry) =>
          setServicesContainerIntersects({
            top: inView,
            bottom: servicesContainerIntersects.bottom,
          })
        }
      >
        <InView
          rootMargin="-100% 0% 0% 0%"
          as="div"
          onChange={(inView, entry) =>
            setServicesContainerIntersects({
              top: servicesContainerIntersects.top,
              bottom: inView,
            })
          }
        >
          <div className={classes.servicesContainer} ref={containerRef}>
            <Box
              className={classes.serviceContainer}
              sx={{
                position:
                  servicesContainerIntersects.top &&
                  servicesContainerIntersects.bottom
                    ? 'fixed'
                    : 'absolute',
                top:
                  servicesContainerIntersects.top &&
                  !servicesContainerIntersects.bottom
                    ? 'unset'
                    : 0,
                bottom:
                  servicesContainerIntersects.top &&
                  !servicesContainerIntersects.bottom
                    ? 0
                    : 'unset',
              }}
            >
              {headline && (
                <Headline level={21} className={classes.servicesHeadline}>
                  {headline}
                </Headline>
              )}
              <div className={classes.logoContainer}>
                <Icon
                  name="DBNLogoOuter"
                  className={clsx(
                    classes.logoShared,
                    classes.logoOuterBackground,
                  )}
                />
                <Icon
                  name="DBNLogoOuter"
                  className={clsx(classes.logoShared, classes.logoOuter)}
                  ref={logoOuterRef}
                />
                <Icon
                  name="DBNLogoInner"
                  className={clsx(classes.logoShared, classes.logoInner, {
                    active: activeItem === serviceElement?.length,
                  })}
                />

                <div className={classes.serviceDisplay}>
                  {serviceElement?.map(
                    (service: DBN.Contentful.IServiceElement, i: number) => (
                      <div
                        className={clsx(classes.serviceDisplayItem, {
                          active: i === activeItem,
                        })}
                        key={i}
                      >
                        {service.headline}
                      </div>
                    ),
                  )}
                </div>
                <div
                  className={clsx(classes.mobileFakeCursor, {
                    teaserMore:
                      serviceElement && activeItem <= serviceElement.length - 1,
                  })}
                  data-previous-index={activeItem}
                  ref={mobileFakeCursorRef}
                >
                  <More />
                </div>
              </div>
              {serviceElement?.map(
                (service: DBN.Contentful.IServiceElement, i: number) => (
                  <Link
                    className={clsx(classes.serviceDisplayLink, {
                      active: i === activeItem,
                    })}
                    to={service.url!}
                    onMouseEnter={() =>
                      window.setTimeout(() => setCursorType('teaserMore'), 200)
                    }
                    onMouseLeave={() => setCursorType('')}
                    title={service.headline}
                    key={i}
                  />
                ),
              )}
            </Box>
          </div>
        </InView>
      </InView>
    </StyledModule>
  )
}
