import React, { ReactElement, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Slider from '@components/core/slider'

import useCustomCursor from '@system/hooks/useCustomCursor'

import Headline from '@components/text/headline'
import Paragraph from '@components/text/paragraph'
import Module from '@components/core/module'
import { styled, Theme } from '@mui/material/styles'

const PREFIX = 'index'

const classes = {
  familyHeadsRoot: `${PREFIX}-familyHeadsRoot`,
  familyMemberRoot: `${PREFIX}-familyMemberRoot`,
  familyMemberMedia: `${PREFIX}-familyMemberMedia`,
  familyMemberImage: `${PREFIX}-familyMemberImage`,
  familyMemberOverlayImage: `${PREFIX}-familyMemberOverlayImage`,
  familyMemberDescription: `${PREFIX}-familyMemberDescription`,
  familyMemberName: `${PREFIX}-familyMemberName`,
  familyMemberPosition: `${PREFIX}-familyMemberPosition`,
}

const StyledModule = styled(Module)(({ theme }) => ({
  [`&.${classes.familyHeadsRoot}`]: {
    position: 'relative',
  },
}))

const StyledFamilyMember = styled('div')(
  ({
    theme,
    hover,
    interactive,
  }: {
    theme: Theme
    hover: boolean
    interactive: boolean
  }) => ({
    [`&.${classes.familyMemberRoot}`]: {},

    [`& .${classes.familyMemberMedia}`]: {
      position: 'relative',
      color: theme.palette.text.invert,
      backgroundColor: theme.palette.background.focus,
      marginBottom: theme.spacing(5),
      overflow: 'hidden',
      '--clip-path': `circle(0px)`,
    },

    [`& .${classes.familyMemberImage}`]: {},

    [`& .${classes.familyMemberOverlayImage}`]: {
      inset: 0,
      visibility: hover ? 'unset' : 'hidden',
      clipPath: 'var(--clip-path)',
    },

    [`& .${classes.familyMemberDescription}`]: {
      transition: 'opacity 0.2s ease-in-out',
      opacity: interactive ? 1 : 0,
      [theme.breakpoints.up('md')]: {
        opacity: hover ? 1 : 0,
      },
    },

    [`& .${classes.familyMemberName}`]: {
      marginBottom: theme.spacing(2),
    },

    [`& .${classes.familyMemberPosition}`]: {
      marginBottom: theme.spacing(1),
    },
  }),
)

export type FamilyMemberProps = {
  theme: Theme
  name?: string
  position?: string
  image?: DBN.Contentful.IAsset
  overlayImage?: DBN.Contentful.IAsset
  animating: boolean
  interactive?: boolean
}

export type FamilyHeadsProps = DBN.IReactDefaultProps & {
  theme?: Theme
  moduleTheme?: string
  anchor?: string
  headline?: string
  members?: Array<FamilyMemberProps>
}

function FamilyMember({
  name,
  position,
  image,
  overlayImage,
  animating,
  interactive,
  theme,
}: FamilyMemberProps): ReactElement {
  const [active, setActive] = useState<boolean>(false)

  const { setCursorType } = useCustomCursor()

  const gatsbyImage = image ? getImage(image) : null
  const gatsbyOverlayImage = overlayImage ? getImage(overlayImage) : null

  const familyMemberMediaRef = useRef<HTMLDivElement>(null)

  const handleMouseOver = () => {
    setCursorType('hide')
    if (!active) {
      setActive(true)
      window.requestAnimationFrame(setImageClipPath)
    }
  }
  const handleMouseLeave = () => {
    setActive(false)
  }

  let start: number
  function setImageClipPath(timestamp: number) {
    if (!start) start = timestamp
    const imageRect = familyMemberMediaRef.current?.getBoundingClientRect()
    if (!imageRect) return

    if (timestamp - start < 500) {
      familyMemberMediaRef.current?.style.setProperty(
        '--clip-path',
        `circle(${Math.min(((timestamp - start) / 500) * 170, 170)}px at calc(var(--x) - ${imageRect.x}px) calc(var(--y) - ${imageRect.y}px))`,
      )
      window.requestAnimationFrame(setImageClipPath)
    } else {
      familyMemberMediaRef.current?.style.setProperty(
        '--clip-path',
        `circle(170px at calc(var(--x) - ${imageRect.x}px) calc(var(--y) - ${imageRect.y}px))`,
      )
    }
  }

  return (
    <StyledFamilyMember
      className={clsx(classes.familyMemberRoot)}
      onMouseEnter={() => setCursorType('')}
      hover={Boolean(active && !animating && interactive)}
      theme={theme}
      interactive={interactive || false}
    >
      <div
        className={classes.familyMemberMedia}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
        ref={familyMemberMediaRef}
      >
        {image && gatsbyImage && (
          <GatsbyImage
            image={gatsbyImage}
            alt={image.description || ''}
            title={image.title}
            className={classes.familyMemberImage}
          />
        )}
        {overlayImage && gatsbyOverlayImage ? (
          <GatsbyImage
            image={gatsbyOverlayImage}
            alt={overlayImage.description || ''}
            title={overlayImage.title}
            className={classes.familyMemberOverlayImage}
            style={{
              position: 'absolute',
            }}
          />
        ) : (
          image &&
          gatsbyImage && (
            <GatsbyImage
              image={gatsbyImage}
              alt={image.description || ''}
              title={image.title}
              className={classes.familyMemberOverlayImage}
              style={{ position: 'absolute', filter: 'grayscale(1)' }}
            />
          )
        )}
      </div>
      <div className={classes.familyMemberDescription}>
        {name && (
          <Headline level={3} className={classes.familyMemberName}>
            {name}
          </Headline>
        )}
        {position && (
          <Paragraph className={classes.familyMemberPosition}>
            {position}
          </Paragraph>
        )}
      </div>
    </StyledFamilyMember>
  )
}

export default function FamilyHeads({
  theme,
  moduleTheme,
  anchor,
  headline,
  members,
}: FamilyHeadsProps): ReactElement {
  const [animating, setAnimating] = useState(false)

  return (
    <StyledModule
      theme={theme}
      moduleTheme={moduleTheme}
      anchor={anchor}
      className={classes.familyHeadsRoot}
    >
      <Slider
        headline={headline}
        spacing={{ mobile: 25, desktop: 'none' }}
        maxElementWidth={336}
        interactiveElements={'visible'}
        setInteractiveProp={true}
        padding={{
          mobile: `${theme?.spacing(48)} 0px ${theme?.spacing(16)}`,
          desktop: `${theme?.spacing(60)} 0px ${theme?.spacing(20)}`,
        }}
        parallaxHeadline={true}
        onSlideNextTransitionStart={() => setAnimating(true)}
        onSlidePrevTransitionStart={() => setAnimating(true)}
        onSlideChangeTransitionEnd={() => setAnimating(false)}
      >
        {members?.map((member: FamilyMemberProps, index: number) => (
          <FamilyMember
            theme={theme!}
            key={index}
            name={member.name}
            position={member.position}
            image={member.image}
            overlayImage={member.overlayImage}
            animating={animating}
          />
        ))}
      </Slider>
    </StyledModule>
  )
}
