import React, { ReactElement } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import clsx from 'clsx'
import useCustomCursor from '@system/hooks/useCustomCursor'
import Icon from '@components/core/icon'
import FontSize from '@config/theme/definitions/fontSize'

const PREFIX = 'index'

const classes = {
  root: `${PREFIX}-root`,
  icon: `${PREFIX}-icon`,
  iconSvg: `${PREFIX}-iconSvg`,
}

const Root = styled('a')(({ theme }) => ({
  [`&.${classes.root}`]: {
    '@media (hover: hover)': {
      [`&:hover .${classes.icon}`]: {
        '&::before': {
          transform: 'scale(1)',
        },
      },
    },
    display: 'inline-flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontFamily: theme.typography.fontFamily,
    fontSize: FontSize['lg'],
    lineHeight: 1.11111,
  },

  [`& .${classes.icon}`]: {
    position: 'relative',
    display: 'inline-block',
    marginRight: theme.spacing(4),
    border: 0,
    outline: 0,
    background: 'none',
    padding: 0,
    font: 'inherit',
    color: 'inherit',
    '@media (hover: hover)': {
      '&::before': {
        content: '""',
        position: 'absolute',
        display: 'block',
        top: '50%',
        left: '50%',
        width: '48px',
        height: '48px',
        backgroundColor: '#FF2001',
        borderRadius: '50%',
        margin: '-24px',
        transform: 'scale(0)',
        transition: 'transform 0.3s',
      },
      '&:hover': {
        color: theme.palette.text.primary,
      },
    },
  },

  [`& .${classes.iconSvg}`]: {
    position: 'relative',
  },
}))

export type DownloadProps = DBN.IReactDefaultProps & {
  to?: string
}

export default function Download({
  to,
  children,
  className,
  ...props
}: DownloadProps): ReactElement {
  const { setCursorType } = useCustomCursor()

  return (
    <Root
      className={clsx(className, classes.root)}
      href={to}
      target="_blank"
      rel="noreferrer"
      onMouseEnter={() => setCursorType('hide')}
      onMouseLeave={() => setCursorType('')}
      {...props}
      theme={useTheme()}
    >
      <span className={classes.icon}>
        <Icon name="Download" className={classes.iconSvg} />
      </span>
      <span>{children}</span>
    </Root>
  )
}
