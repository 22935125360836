import React, {
  ReactElement,
  useRef,
  useEffect,
  useState,
  useCallback,
} from 'react'
import clsx from 'clsx'
import useCustomCursor from '@system/hooks/useCustomCursor'
import { useInView } from 'react-intersection-observer'
import { styled, Theme } from '@mui/material/styles'

const PREFIX = 'index'

const classes = {
  sliderGridContainer: `${PREFIX}-sliderGridContainer`,
  prevArea: `${PREFIX}-prevArea`,
  nextArea: `${PREFIX}-nextArea`,
  sliderGridList: `${PREFIX}-sliderGridList`,
  sliderGridItem: `${PREFIX}-sliderGridItem`,
  sliderGridItemActive: `${PREFIX}-sliderGridItemActive`,
  headline: `${PREFIX}-headline`,
  headlineInView: `${PREFIX}-headlineInView`,
}

const Root = styled('div')(
  ({
    theme,
    padding,
    parallaxHeadline,
  }: {
    theme: Theme
    padding?: { mobile?: string; desktop?: string }
    parallaxHeadline?: boolean
  }) => ({
    [`&.${classes.sliderGridContainer}`]: {
      position: 'relative',
      width: '100%',
      padding: padding
        ? padding.mobile
        : `${theme.spacing(24)} 0px ${theme.spacing(16)}`,
      [theme.breakpoints.up('md')]: {
        padding: padding
          ? padding.desktop
          : `${theme.spacing(20)} 0px ${theme.spacing(18)}`,
      },
    },

    [`& .${classes.prevArea}`]: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '50%',
      height: '100%',
    },

    [`& .${classes.nextArea}`]: {
      position: 'absolute',
      top: 0,
      right: 0,
      width: '50%',
      height: '100%',
    },

    [`& .${classes.sliderGridList}`]: {
      position: 'relative',
      display: 'flex',
      width: '100%',
      flexWrap: 'nowrap',
      pointerEvents: 'none',
      transform: 'translateZ(0)',
      transition: 'left 0.8s ease-in-out',
      listStyle: 'none',
      padding: '0',
      margin: 0,
    },

    [`& .${classes.sliderGridItem}`]: {
      padding: 0,
      margin: 0,
    },

    [`& .${classes.sliderGridItemActive}`]: {
      pointerEvents: 'auto',
    },

    [`& .${classes.headline}`]: {
      opacity: 0,
      transition: 'opacity 0.6s ease-in',
      position: 'absolute',
      zIndex: 0,
      margin: '0 auto',
      top: 0,
      width: '100%',
      textAlign: parallaxHeadline ? 'left' : 'center',
      ...theme.typography.sliderbg,

      [theme.breakpoints.up('md')]: {
        display: 'flex',
        justifyContent: 'center',
        top: parallaxHeadline ? '0' : '50%',
        transform: parallaxHeadline ? 'translateY(0)' : 'translateY(-50%)',
        paddingLeft: '20vw',
        paddingRight: '20vw',
      },
    },

    [`& .${classes.headlineInView}`]: {
      opacity: 1,
    },
  }),
)

type Spacing = 'auto' | 'none' | number

export type SliderProps = DBN.IReactDefaultProps & {
  headline?: string
  onSlideNextTransitionStart?: () => void
  onSlidePrevTransitionStart?: () => void
  onSlideChangeTransitionEnd?: () => void
  spacing?: Spacing | { mobile: Spacing; desktop: Spacing }
  maxElementWidth?: number
  interactiveElements?: number | 'visible'
  setInteractiveProp?: boolean
  padding?: { mobile?: string; desktop?: string }
  parallaxHeadline?: boolean
}

export default function Slider({
  headline,
  children,
  onSlideNextTransitionStart,
  onSlidePrevTransitionStart,
  onSlideChangeTransitionEnd,
  spacing,
  maxElementWidth,
  interactiveElements,
  setInteractiveProp,
  padding,
  parallaxHeadline,
}: SliderProps): ReactElement {
  const cnt = React.Children.count(children) || 0

  const [sliderPosX, setSliderPosX] = useState(0)
  const [windowWidth, setWindowWidth] = useState(0)

  const sliderRef = useRef<HTMLUListElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const headlineRef = useRef<HTMLDivElement | null>(null)

  const { ref: inViewRef, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
    delay: 100,
  })

  const setHeadlineRefs = useCallback(
    (node: HTMLDivElement) => {
      headlineRef.current = node
      inViewRef(node)
    },
    [inViewRef],
  )

  const bP = 768

  useEffect(() => {
    function handleResize() {
      setWindowWidth(() => {
        return document.documentElement.clientWidth || document.body.clientWidth
      })
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const selectPrev = () => {
    if (sliderPosX > 0) {
      if (onSlidePrevTransitionStart) onSlidePrevTransitionStart()
      setSliderPosX(sliderPosX - 1)
      if (onSlideChangeTransitionEnd)
        setTimeout(() => onSlideChangeTransitionEnd(), 800)
    }

    if (sliderPosX <= 1) {
      setCursorType('')
    }
  }

  const selectNext = () => {
    if (sliderPosX < cnt - getActiveElements()) {
      if (onSlideNextTransitionStart) onSlideNextTransitionStart()
      setSliderPosX(sliderPosX + 1)
      if (onSlideChangeTransitionEnd)
        setTimeout(() => onSlideChangeTransitionEnd(), 800)
    }
    if (sliderPosX >= cnt - getActiveElements() - 1) {
      setCursorType('')
    }
  }

  const getElementWidth = () => {
    if (windowWidth < bP) {
      return Math.min(maxElementWidth || 520, windowWidth - 48)
    }
    return Math.min(maxElementWidth || 520, (windowWidth - 112) / 2)
  }

  const getSpace = () => {
    if (spacing === 'none') return 0
    if (typeof spacing === 'number') return spacing
    if (windowWidth < bP) {
      if (typeof spacing === 'object' && spacing.mobile === 'none') return 0
      if (typeof spacing === 'object' && typeof spacing.mobile === 'number')
        return spacing.mobile
      return windowWidth - getSpaceStart() - getElementWidth() - 16
    }
    if (typeof spacing === 'object' && spacing.desktop === 'none') return 0
    if (typeof spacing === 'object' && typeof spacing.desktop === 'number')
      return spacing.desktop
    return windowWidth - getSpaceStart() - 1.75 * getElementWidth()
  }

  const getSpaceStart = () => {
    if (windowWidth < bP) {
      return 16
    }
    return Math.max(40, (windowWidth - 1072) * 0.5)
  }

  const getPosX = () => {
    return -sliderPosX * (getElementWidth() + getSpace())
  }

  const getActiveElements = () => {
    if (interactiveElements === 'visible') {
      return Math.max(
        Math.floor(
          (windowWidth - getSpaceStart() * 2) /
            (getElementWidth() + getSpace()),
        ),
        1,
      )
    }
    if (typeof interactiveElements === 'number') return interactiveElements
    return 1
  }

  const { setCursorType } = useCustomCursor()

  useEffect(() => {
    let touchStart = 0
    let touchEnd = 0

    function handleTouchStart(e: TouchEvent) {
      touchStart = e.targetTouches[0].clientX
      touchEnd = e.targetTouches[0].clientX
    }

    function handleTouchMove(e: TouchEvent) {
      touchEnd = e.targetTouches[0].clientX
    }

    function handleTouchEnd() {
      const diff = 50
      if (touchStart - touchEnd > diff) {
        selectNext()
      }

      if (touchStart - touchEnd < -diff) {
        selectPrev()
      }
      touchStart = 0
      touchEnd = 0
    }

    containerRef.current?.addEventListener('touchstart', handleTouchStart)
    containerRef.current?.addEventListener('touchmove', handleTouchMove)
    containerRef.current?.addEventListener('touchend', handleTouchEnd)
    containerRef.current?.addEventListener('touchcancel', handleTouchEnd)

    return () => {
      containerRef.current?.removeEventListener('touchstart', handleTouchStart)
      containerRef.current?.removeEventListener('touchmove', handleTouchMove)
      containerRef.current?.removeEventListener('touchend', handleTouchEnd)
      containerRef.current?.removeEventListener('touchcancel', handleTouchEnd)
    }
  })

  if (parallaxHeadline) {
    let offset
    useEffect(() => {
      const handleScroll = () => {
        if (containerRef.current && headlineRef.current) {
          const containerRect = containerRef.current.getBoundingClientRect()
          const headlineRect = headlineRef.current.getBoundingClientRect()

          const start = containerRect.top - window.innerHeight
          const stop = containerRect.top + containerRect.height

          if (start < 0 && 0 < stop) {
            const position = start / (start - stop)
            offset =
              (position * (containerRect.height - headlineRect.height)) / 2
            headlineRef.current.style.transform = `translateY(${offset}px)`
          }
        }
      }
      window.addEventListener('scroll', handleScroll)
      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }, [])
  }

  return (
    <>
      {cnt && cnt > 0 && (
        <Root
          className={classes.sliderGridContainer}
          ref={containerRef}
          padding={padding}
          parallaxHeadline={parallaxHeadline}
        >
          {headline && (
            <div
              ref={setHeadlineRefs}
              className={clsx(classes.headline, {
                [classes.headlineInView]: inView,
              })}
            >
              {headline}
            </div>
          )}
          <div
            className={classes.prevArea}
            onClick={selectPrev}
            onMouseEnter={() => {
              setCursorType(sliderPosX > 0 ? 'arrow-left' : '')
            }}
            onMouseLeave={() => setCursorType('')}
          />
          <div
            className={classes.nextArea}
            onClick={selectNext}
            onMouseEnter={() => {
              setCursorType(
                sliderPosX < cnt - getActiveElements() ? 'arrow-right' : '',
              )
            }}
            onMouseLeave={() => setCursorType('')}
          />
          <ul
            className={classes.sliderGridList}
            ref={sliderRef}
            style={{
              left: `${getPosX()}px`,
              gap: `${getSpace()}px`,
              marginLeft: `${getSpaceStart()}px`,
            }}
          >
            {(() => {
              return React.Children.map(children, (child, index) => {
                return React.cloneElement(
                  <li
                    className={clsx(classes.sliderGridItem, {
                      [classes.sliderGridItemActive]:
                        index >= sliderPosX &&
                        index <= sliderPosX + getActiveElements() - 1,
                    })}
                    key={`gallery-item-${index}`}
                    style={{ flex: `0 0 ${getElementWidth()}px` }}
                  >
                    {setInteractiveProp
                      ? React.cloneElement(child as React.ReactElement, {
                          interactive:
                            index >= sliderPosX &&
                            index <= sliderPosX + getActiveElements() - 1,
                        })
                      : child}
                  </li>,
                )
              })
            })()}
          </ul>
        </Root>
      )}
    </>
  )
}
