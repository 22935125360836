import React, { useEffect, useRef } from 'react'
import { styled, Theme } from '@mui/material/styles'
import clsx from 'clsx'
import useModuleTheme from '@system/hooks/useModuleTheme'

const PREFIX = 'index'

const classes = {
  moduleRoot: `${PREFIX}-moduleRoot`,
  moduleThemeDark: `${PREFIX}-moduleThemeDark`,
  moduleThemeLight: `${PREFIX}-moduleThemeLight`,
  moduleThemeYellow: `${PREFIX}-moduleThemeYellow`,
  moduleThemeGrey: `${PREFIX}-moduleThemeGrey`,
  moduleContent: `${PREFIX}-moduleContent`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.moduleRoot}`]: {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      zIndex: 1,
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
    },
  },

  [`&.${classes.moduleThemeDark}`]: {
    '--background-color': theme.palette.background.focus,
    color: theme.palette.text.invert,
    '&::before': {
      backgroundColor: theme.palette.background.focus,
    },
    '&::after': {
      backgroundColor: theme.palette.background.focus,
    },
  },

  [`&.${classes.moduleThemeLight}`]: {
    '--background-color': theme.palette.background.default,
    color: theme.palette.text.default,
    '&::before': {
      backgroundColor: theme.palette.background.default,
    },
    '&::after': {
      backgroundColor: theme.palette.background.default,
    },
  },

  [`&.${classes.moduleThemeYellow}`]: {
    '--background-color': theme.palette.secondary.main,
    color: theme.palette.text.invert,
    '&::before': {
      backgroundColor: theme.palette.secondary.main,
    },
    '&::after': {
      backgroundColor: theme.palette.secondary.main,
    },
  },

  [`&.${classes.moduleThemeGrey}`]: {
    '--background-color': '#F3F5F9',
    color: theme.palette.text.default,
    '&::before': {
      backgroundColor: '#F3F5F9',
    },
  },

  [`& .${classes.moduleContent}`]: {
    position: 'relative',
    zIndex: 5,
    '&::before': {
      content: '""',
      display: 'table',
    },
    '&::after': {
      content: '""',
      display: 'table',
    },
  },
}))

export type ModuleProps = DBN.IReactDefaultProps & {
  moduleTheme?: string
  theme?: Theme
  anchor?: string
}

export default function Module({
  moduleTheme,
  theme,
  anchor,
  children,
  className,
  ...props
}: ModuleProps): React.ReactElement {
  const { changeTheme } = useModuleTheme()
  const moduleRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleScroll = () => {
      if (moduleRef.current) {
        if (moduleRef.current.parentElement?.classList.contains('exit')) return
        const moduleRect = moduleRef.current.getBoundingClientRect()
        if (moduleRect.top <= 0 && moduleRect.bottom > 0) {
          if (!!moduleTheme && ['dark', 'yellow'].includes(moduleTheme)) {
            changeTheme('dark')
          } else {
            changeTheme('light')
          }
        }
      }
    }

    setTimeout(() => handleScroll(), 100)

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [moduleRef, changeTheme, theme])

  return (
    <Root
      {...(anchor ? { id: anchor } : {})}
      className={clsx(className, classes.moduleRoot, {
        [classes.moduleThemeDark]: moduleTheme == 'dark',
        [classes.moduleThemeLight]: moduleTheme == 'light',
        [classes.moduleThemeYellow]: moduleTheme == 'yellow',
        [classes.moduleThemeGrey]: moduleTheme == 'grey',
      })}
      ref={moduleRef}
      {...props}
    >
      <div className={clsx('moduleContent', classes.moduleContent)}>
        {children}
      </div>
    </Root>
  )
}
