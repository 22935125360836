import React, { ReactElement, useCallback, useEffect, useRef } from 'react'
import { styled, Theme } from '@mui/material/styles'
import Module from '@components/core/module'
import { useInView } from 'react-intersection-observer'
import Headline from '@components/text/headline'
import Fact1Background from '../../../../../static/img/svgIcons/fact1.background.inline.svg'
import Fact2Background from '../../../../../static/img/svgIcons/fact2.background.inline.svg'
import Fact3Background from '../../../../../static/img/svgIcons/fact3.background.inline.svg'
import Fact4Background from '../../../../../static/img/svgIcons/fact4.background.inline.svg'
import { useMediaQuery } from '@mui/material'

export type FactsProps = DBN.IReactDefaultProps & {
  titleInternal?: string
  theme?: Theme
  moduleTheme?: string
  anchor?: string
  fact1?: string
  fact2?: string
  fact3?: string
  fact4?: string
}

const FactsRoot = styled((props: FactsProps & { facts: string[] }) => (
  <Module {...props} />
))(({ theme, facts }) => ({
  '--module-height': `${60 * facts.length}vh`,
  backgroundColor: 'var(--background-color)',
  height: 'var(--module-height)',
  width: '100vw',
  overflow: 'hidden',
  [theme.breakpoints.up('md')]: {
    '--module-height': `${50 * facts.length}vh`,
  },

  '.in-view': {
    height: 'var(--module-height)',
  },

  '.facts-container': {
    '--fact-size': '342px',
    display: 'flex',
    height: `calc(calc(calc(var(--fact-size) + ${theme.spacing(8)}) * ${facts.length}) - ${theme.spacing(8)})`,
    transform: `translateY(calc(calc(calc(var(--module-height) - 110%) * var(--progress)) + 5%))`,
    flexDirection: 'column',
    gap: theme.spacing(8),

    [theme.breakpoints.up('md')]: {
      '--fact-size': '428px',
      alignItems: 'end',
      height: '100vh',
      width: `calc(calc(calc(var(--fact-size) + ${theme.spacing(8)}) * ${facts.length}) - ${theme.spacing(8)})`,
      transform: `translate(calc(75vw - calc(calc(50vw + 100%) * var(--progress))), calc(calc(calc(var(--module-height) - 50%) * var(--progress)) - 25%))`,
      flexDirection: 'row',
    },
  },

  '.fact': {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 8),
    width: 'var(--fact-size)',
    transform:
      'translateX(calc(calc(100vw - 100%) - calc(calc(100vw - 100%) * var(--element-progress))))',
    flex: '0 0 var(--fact-size)',
    transition: 'opacity 0.1s ease-in',

    [theme.breakpoints.up('md')]: {
      height: 'var(--fact-size)',
      transform:
        'translateY(calc(calc(100% - 100vh) * var(--element-progress)))',
    },

    '.background-svg': {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      zIndex: -1,
    },

    h2: {
      marginBottom: 'unset',
      fontSize: 36,
      opacity: 0,

      [theme.breakpoints.up('md')]: {
        fontSize: 56,
      },
    },

    '&[data-in-view] h2': {
      opacity: 1,
    },

    '&[data-in-view]:nth-child(1) h2': {
      transition: 'opacity 0.25s ease-in 0.75s',
    },

    '&[data-in-view]:nth-child(2) h2': {
      transition: 'opacity 0.25s ease-in 0.95s',
    },

    '&[data-in-view]:nth-child(3) h2': {
      transition: 'opacity 0.25s ease-in 1.15s',
    },

    '&[data-in-view]:nth-child(4) h2': {
      transition: 'opacity 0.25s ease-in 1.35s',
    },
  },
}))

export default function Facts({
  theme,
  moduleTheme,
  anchor,
  fact1,
  fact2,
  fact3,
  fact4,
}: FactsProps): ReactElement {
  const facts = [fact1, fact2, fact3, fact4].filter((fact) => fact) as string[]

  const moduleRef = useRef<HTMLDivElement | null>(null)
  const { ref: inViewTopRef, inView: inViewTop } = useInView({
    rootMargin: '0% 0% -100% 0%',
  })
  const { ref: inViewBottomRef, inView: inViewBottom } = useInView({
    rootMargin: '-100% 0% 0% 0%',
  })

  const setRefs = useCallback(
    (node: HTMLDivElement) => {
      moduleRef.current = node
      inViewTopRef(node)
      inViewBottomRef(node)
    },
    [inViewTopRef, inViewBottomRef],
  )

  const factElements = moduleRef.current?.querySelectorAll<HTMLElement>('.fact')
  const factsInView = [
    useInView({ threshold: [0.5, 0] }),
    useInView({ threshold: [0.5, 0] }),
    useInView({ threshold: [0.5, 0] }),
    useInView({ threshold: [0.5, 0], skip: !fact4 }),
  ]
  const backkgrounds = [
    <Fact1Background />,
    <Fact2Background />,
    <Fact3Background />,
    <Fact4Background />,
  ]

  const desktop = useMediaQuery(theme!.breakpoints.up('md'))

  useEffect(() => {
    if (!(inViewTop || inViewBottom)) return

    function handleScroll() {
      if (!moduleRef.current) return
      const containerRect = moduleRef.current?.getBoundingClientRect()

      const totalDistance = containerRect.height + window.innerHeight
      const position = window.innerHeight - containerRect.top
      const progress = position / totalDistance
      moduleRef.current.style.setProperty('--progress', String(progress))

      factElements?.forEach((factElement) => {
        const factRect = factElement.getBoundingClientRect()
        if (desktop) {
          factElement.style.setProperty(
            '--element-progress',
            String(
              (-1 / (containerRect.width + factRect.width)) *
                (factRect.left + factRect.width) +
                1,
            ),
          )
        } else {
          factElement.style.setProperty(
            '--element-progress',
            String(
              (-1 / (window.innerHeight + factRect.height)) *
                (factRect.top + factRect.height) +
                1,
            ),
          )
        }
      })
    }

    handleScroll()
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [inViewTop, inViewBottom])

  factElements?.forEach((factElement, i) => {
    if (
      factsInView[i].inView &&
      factsInView[i].entry?.intersectionRatio &&
      factsInView[i].entry?.intersectionRatio > 0.5 &&
      factElement.dataset.inView === undefined
    ) {
      factElement.dispatchEvent(new Event('beginEvent'))
      factElement.dataset.inView = ''
      factElement.style.opacity = '1'
    } else if (
      !factsInView[i].inView &&
      !factsInView[i].entry?.intersectionRatio &&
      factsInView[i].entry?.boundingClientRect.x! > 0 &&
      factsInView[i].entry?.boundingClientRect.y! > 0
    ) {
      delete factElement.dataset.inView
      factElement.style.opacity = '0'
    }
  })

  return (
    <FactsRoot
      theme={theme}
      moduleTheme={moduleTheme}
      anchor={anchor}
      facts={facts}
    >
      <div className="in-view" ref={setRefs}>
        <div className="facts-container">
          {facts.map((fact, i) => (
            <div className="fact" id={`fact${i + 1}`} ref={factsInView[i].ref}>
              <Headline level={2}>{fact}</Headline>
              {backkgrounds[i]}
            </div>
          ))}
        </div>
      </div>
    </FactsRoot>
  )
}
