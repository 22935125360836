import React, { ReactElement } from 'react'
import { styled, Theme } from '@mui/material/styles'
import parse from 'html-react-parser'
import { defaultLang } from '@system/translations'
import { Job } from '@/types/job'

import { Grid } from '@mui/material'

import useGlobalText from '@system/hooks/useGlobalText'
import Container from '@components/modules/global/container'
import Headline from '@components/text/headline'
import Module from '@components/core/module'
import Button from '@components/core/button'

import { useIntl } from 'react-intl'

const PREFIX = 'index'

const classes = {
  jobDetailRoot: `${PREFIX}-jobDetailRoot`,
  jobDetailHeadline: `${PREFIX}-jobDetailHeadline`,
  jobDetailLinkTop: `${PREFIX}-jobDetailLinkTop`,
  jobDetailLinkBottom: `${PREFIX}-jobDetailLinkBottom`,
  jobDetailMetaEntry: `${PREFIX}-jobDetailMetaEntry`,
  jobDetailMetaHead: `${PREFIX}-jobDetailMetaHead`,
  jobDetailMetaBody: `${PREFIX}-jobDetailMetaBody`,
  jobDetailDescription: `${PREFIX}-jobDetailDescription`,
  jobDetailOutro: `${PREFIX}-jobDetailOutro`,
}

const StyledModule = styled(Module)(({ theme }) => ({
  [`&.${classes.jobDetailRoot}`]: {
    padding: theme.spacing(31, 0, 10),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(37, 0, 22),
    },
  },

  [`& .${classes.jobDetailHeadline}`]: {
    marginBottom: theme.spacing(7),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(10),
    },
  },

  [`& .${classes.jobDetailLinkTop}`]: {
    marginBottom: theme.spacing(10),
  },

  [`& .${classes.jobDetailLinkBottom}`]: {
    marginTop: theme.spacing(10),
  },

  [`& .${classes.jobDetailMetaEntry}`]: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(6),
    },
  },

  [`& .${classes.jobDetailMetaHead}`]: {
    ...theme.typography.intrometahead,
  },

  [`& .${classes.jobDetailMetaBody}`]: {
    ...theme.typography.intrometabody,
  },

  [`& .${classes.jobDetailDescription}`]: {
    margin: theme.spacing(10, 0),
    '&:first-child': {
      marginTop: theme.spacing(2),
    },
    '&:last-child': {
      marginBottom: theme.spacing(2),
    },
  },

  [`& .${classes.jobDetailOutro}`]: {
    marginBottom: 0,
  },
}))

export type JobDetailProps = DBN.IReactDefaultProps &
  Partial<Job> & {
    pageContext?: DBN.PageHelpers.PageContext
    theme?: Theme
    moduleTheme?: string
  }

export default function JobDetail({
  pageContext,
  theme,
  moduleTheme,
  name,
  slug,
  department,
  schedule,
  jobDescriptions,
}: JobDetailProps): ReactElement {
  const { getText } = useGlobalText()
  const locale = pageContext?.locale || defaultLang

  const intl = useIntl()

  return (
    <StyledModule
      theme={theme}
      moduleTheme={moduleTheme}
      className={classes.jobDetailRoot}
    >
      <Container type="nomargin">
        {name && (
          <Headline level={1} className={classes.jobDetailHeadline}>
            {name}
          </Headline>
        )}
        <div className={classes.jobDetailLinkTop}>
          <Button
            to={
              locale === defaultLang
                ? `/jobs/application/${slug}`
                : `/${locale}/jobs/application/${slug}`
            }
          >
            {intl.formatMessage({
              id: 'form.application.headline.submit',
            })}
          </Button>
        </div>
        <Grid container justifyContent="space-between" spacing={8}>
          <Grid item xs={12} md={6}>
            {jobDescriptions && jobDescriptions.map((description, index) => (
              <div className={classes.jobDetailDescription} key={index}>
                {description.name && (
                  <Headline level={3}>{parse(description.name)}</Headline>
                )}
                {description.value && parse(description.value)}
              </div>
            ))}
          </Grid>
          <Grid item xs={12} md={5}>
            {schedule && (
              <div className={classes.jobDetailMetaEntry}>
                <div className={classes.jobDetailMetaHead}>
                  {getText('module.jobDetail.schedule') || 'Working hours'}
                </div>
                <div className={classes.jobDetailMetaBody}>
                  {getText(`job.schedule.${schedule}`) || schedule}
                </div>
              </div>
            )}
            {department && (
              <div className={classes.jobDetailMetaEntry}>
                <div className={classes.jobDetailMetaHead}>
                  {getText('module.jobDetail.department') || 'Department'}
                </div>
                <div className={classes.jobDetailMetaBody}>
                  {getText(`job.department.${department}`) || department}
                </div>
              </div>
            )}
          </Grid>
        </Grid>
        <div className={classes.jobDetailLinkBottom}>
          <Button
            to={
              locale === defaultLang
                ? `/jobs/application/${slug}`
                : `/${locale}/jobs/application/${slug}`
            }
          >
            {intl.formatMessage({
              id: 'form.application.headline.submit',
            })}
          </Button>
        </div>
      </Container>
    </StyledModule>
  )
}
