import React, { ReactElement } from 'react'
import useCustomCursor from '@system/hooks/useCustomCursor'
import Module from '@components/core/module'
import Icon from '@components/core/icon'
import FontSize from '@config/theme/definitions/fontSize'
import { styled, Theme } from '@mui/material/styles'
import MuiAccordion, {
  AccordionProps as MuiAccordionProps,
} from '@mui/material/Accordion'
import Summary from '@mui/material/AccordionSummary'
import Details from '@mui/material/AccordionDetails'
import PageLink from '@components/core/pagelink'
import Headline from '@components/text/headline'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const AccordionModule = styled(Module)(({ theme }) => ({
  background: 'var(--background-color)',
  paddingBottom: theme.spacing(24),
  [theme.breakpoints.up('md')]: {
    paddingBottom: theme.spacing(30),
  },

  svg: {
    position: 'absolute',
  },
}))

const AccordionElement = styled((props: MuiAccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  zIndex: 1,
  background: 'var(--background-color)',
  color: '#ffffff',

  '&.Mui-expanded': {
    zIndex: 5,
  },

  '.MuiAccordionSummary-root': {
    cursor: 'none !important',
    padding: theme.spacing(10, 4),
    fontWeight: 700,
    fontSize: FontSize['3xl'],

    [theme.breakpoints.up('md')]: {
      fontSize: FontSize['5xl'],
      padding: theme.spacing(10, 6),
    },

    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(10, 46),
    },

    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },

    '&.Mui-expanded': {
      backgroundColor: theme.palette.secondary.main,
    },

    '.MuiAccordionSummary-content': {
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: theme.spacing(4),

      '&.Mui-expanded .icons': {
        '> *:first-child': {
          opacity: 0,
        },

        '&::before': {
          transform: 'scale(1)',
          backgroundColor: 'var(--background-color)',
        },
      },

      '.label': {
        display: 'flex',
        marginBottom: 'unset',
      },

      '.number': {
        width: theme.spacing(15),
      },

      '.icons': {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flex: '0 0 72px',
        height: 72,

        '&::before': {
          content: '""',
          position: 'absolute',
          display: 'block',
          width: '100%',
          height: '100%',
          borderRadius: '50%',
          transform: 'scale(0)',
          transition: 'transform 0.3s, background-color 0.3s, color 0.3s',
        },

        '@media (hover: hover)': {
          '&:hover': {
            color: theme.palette.text.primary,
            '&::before': {
              transform: 'scale(1)',
              backgroundColor: '#FF2001',
            },
          },
        },

        svg: {
          width: 32,
          height: 32,
          transition: 'opacity 0.5s ease',

          [theme.breakpoints.up('md')]: {
            width: 48,
            height: 48,
          },
        },
      },
    },
  },

  '.MuiAccordionDetails-root': {
    display: 'flex',
    flexDirection: 'column-reverse',
    gap: theme.spacing(6),
    padding: theme.spacing(0, 8, 10, 19),
    backgroundColor: theme.palette.secondary.main,

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center',
      gap: theme.spacing(10),
      padding: theme.spacing(0, 22, 26),
    },

    [theme.breakpoints.up('xl')]: {
      gap: theme.spacing(31),
      padding: theme.spacing(0, 46, 26),
    },

    '--scaleX': '1',
    '&::after': {
      content: '""',
      position: 'absolute',
      zIndex: -1,
      height: `calc(${theme.spacing(3)})`,
      top: 'unset',
      bottom: theme.spacing(-3),
      width: '100%',
      minWidth: '600px',
      left: '50%',
      clipPath: 'url(#my-clip-path)',
      backgroundColor: theme.palette.secondary.main,
      transform: 'translateX(-50%) rotate(0.5turn) scale(var(--scaleX), 1)',
    },

    '.case': {
      width: '100%',
      maxWidth: 400,
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),

      [theme.breakpoints.up('md')]: {
        flex: '0 0 336px',
      },

      h3: {
        marginBottom: 'unset',
      },

      '.caseImage': {
        overflow: 'hidden',

        img: {
          pointerEvents: 'none',
          transition: 'transform 0.6s ease-out',
        },

        '&:hover img': {
          transform: 'scale(1.05)',
          transition: 'transform 0.6s ease-in',
        },
      },
    },
  },
}))

export type AccordionProps = DBN.IReactDefaultProps & {
  titleInternal?: string
  theme: Theme
  moduleTheme?: string
  anchor?: string
  accordionElements?: Array<DBN.Contentful.IAccordionElement>
}

export default function Accordion({
  theme,
  moduleTheme,
  anchor,
  accordionElements,
}: AccordionProps): ReactElement {
  const [expanded, setExpanded] = React.useState<number | null>(0)

  const handleChange = (index: number) => (event: React.SyntheticEvent) => {
    setExpanded(index !== expanded ? index : null)
  }
  console.log(accordionElements)
  const { setCursorType } = useCustomCursor()

  return (
    <AccordionModule theme={theme} moduleTheme={moduleTheme} anchor={anchor}>
      <svg width="0" height="0">
        <clipPath id="my-clip-path" clipPathUnits="objectBoundingBox">
          <path d="M0,1 C0.3,-0.333,0.7,-0.333,1,1"></path>
        </clipPath>
      </svg>

      {accordionElements?.map(
        (accordionElement: DBN.Contentful.IAccordionElement, i: number) => {
          const cursor = accordionElement.case?.fields
            ? accordionElement.case.fields?.isExternal
              ? 'teaserExternal'
              : 'teaserMore'
            : ''
          const image = accordionElement.case?.teaserCardImage
            ? getImage(accordionElement.case.teaserCardImage)
            : null

          return (
            <AccordionElement
              expanded={expanded === i}
              onChange={handleChange(i)}
              key={i}
            >
              <Summary
                aria-controls={`${i}-content`}
                id={`${i}-header`}
                onMouseEnter={() => setCursorType('link')}
                onMouseLeave={() => setCursorType('')}
              >
                <Headline level={2} className="label">
                  <div className="number">{String(i).padStart(2, '0')}</div>
                  {accordionElement.title}
                </Headline>
                <div
                  className="icons"
                  onMouseEnter={() => setCursorType('hide')}
                  onMouseLeave={() => setCursorType('link')}
                >
                  <Icon name={'MoreSummary'}></Icon>
                  <Icon name={'LessSummary'}></Icon>
                </div>
              </Summary>
              <Details>
                {accordionElement.case?.teaserCardImage && image && (
                  <PageLink page={accordionElement.case} className="case">
                    <div
                      onMouseEnter={() => setCursorType(cursor)}
                      onMouseLeave={() => setCursorType('')}
                      onClick={() => setCursorType('')}
                      className="caseImage"
                    >
                      <GatsbyImage
                        image={image}
                        alt={
                          accordionElement.case?.teaserCardImage.description ||
                          ''
                        }
                        title={accordionElement.case?.teaserCardImage.title}
                      />
                    </div>
                    <Headline level={3}>{accordionElement.caseLabel}</Headline>
                  </PageLink>
                )}
                {accordionElement.copy?.childMarkdownRemark?.html}
              </Details>
            </AccordionElement>
          )
        },
      )}
    </AccordionModule>
  )
}
