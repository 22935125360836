import React, { ReactElement } from 'react'
import { styled, Theme } from '@mui/material/styles'
import clsx from 'clsx'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Container from '@components/modules/global/container'
import Blockquote from '@components/text/blockquote'
import Module from '@components/core/module'

const PREFIX = 'index'

const classes = {
  quoteRoot: `${PREFIX}-quoteRoot`,
  quoteContainer: `${PREFIX}-quoteContainer`,
  quoteImage: `${PREFIX}-quoteImage`,
  quoteText: `${PREFIX}-quoteText`,
  quoteIdent: `${PREFIX}-quoteIdent`,
}

const StyledModule = styled(Module)(({ theme }) => ({
  [`&.${classes.quoteRoot}`]: {
    paddingTop: theme.spacing(16),
    paddingBottom: theme.spacing(16),

    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(30),
      paddingBottom: theme.spacing(20),
    },
  },

  [`& .${classes.quoteContainer}`]: {
    position: 'relative',
  },

  [`& .${classes.quoteImage}`]: {
    position: 'relative',
    marginTop: theme.spacing(10),
    marginRight: theme.spacing(-4),
    marginBottom: theme.spacing(-16),
    marginLeft: theme.spacing(-4),
    textAlign: 'center',
    zIndex: 1,

    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      top: theme.spacing(-10),
      left: theme.spacing(10),
      margin: 0,
      width: '247px',
    },
  },

  [`& .${classes.quoteText}`]: {
    position: 'relative',
    zIndex: 2,
  },

  [`& .${classes.quoteIdent}`]: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(46),
      paddingTop: theme.spacing(10),
    },
  },
}))

export type QuoteProps = DBN.IReactDefaultProps & {
  theme?: Theme
  moduleTheme?: string
  anchor?: string
  quote?: string
  author?: string
  position?: string
  image?: DBN.Contentful.IAsset
  fontsize?: string
}

export default function ModuleQuote({
  theme,
  moduleTheme,
  anchor,
  quote,
  author,
  position,
  image,
  fontsize,
}: QuoteProps): ReactElement {
  const gatsbyImage = image ? getImage(image) : null

  return (
    <StyledModule
      className={classes.quoteRoot}
      theme={theme}
      moduleTheme={moduleTheme}
      anchor={anchor}
    >
      <Container type="nomargin" className={clsx(classes.quoteContainer)}>
        {quote && (
          <div
            className={clsx(classes.quoteText, {
              [classes.quoteIdent]: !!image,
            })}
          >
            <Blockquote
              text={quote}
              author={author}
              position={position}
              fontsize={fontsize}
            />
          </div>
        )}
        {image && gatsbyImage && (
          <div className={clsx(classes.quoteImage)}>
            <GatsbyImage
              image={gatsbyImage}
              alt={image.description || ''}
              title={image.title}
              imgStyle={{
                objectFit: 'cover',
                objectPosition: 'center center',
              }}
            />
          </div>
        )}
      </Container>
    </StyledModule>
  )
}
