import React, { ReactElement } from 'react'
import DefaultStageVariant, { DefaultStageProps } from './variants/default'
import { Theme } from '@mui/material'

type StagePropsUnion = DefaultStageProps

export type StageProps = DBN.IReactDefaultProps & {
  theme?: Theme
  titleInternal?: string
  type?: string
} & StagePropsUnion

export default function Stage({
  type,
  theme,
  ...props
}: StageProps): ReactElement {
  const extendedProps = { type: type?.toLocaleLowerCase(), ...props }
  const widthParallax = { parallax: true, ...extendedProps }
  switch (type?.toLowerCase()) {
    case 'work':
      return <DefaultStageVariant {...widthParallax} theme={theme} />
    default:
      return <DefaultStageVariant {...extendedProps} theme={theme} />
  }
}
