import React, { ReactElement } from 'react'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import parse from 'html-react-parser'

import { Theme } from '@mui/material/styles'

import FontSize from '@config/theme/definitions/fontSize'

const PREFIX = 'index'

const classes = {
  blockquote: `${PREFIX}-blockquote`,
  blockquoteText: `${PREFIX}-blockquoteText`,
  blockquoteTextCite: `${PREFIX}-blockquoteTextCite`,
  blockquoteFooter: `${PREFIX}-blockquoteFooter`,
  blockquoteAuthor: `${PREFIX}-blockquoteAuthor`,
  blockquotePosition: `${PREFIX}-blockquotePosition`,
}

const Root = styled('blockquote', {
  shouldForwardProp: (prop) => prop !== 'fontsize',
})(({ theme, fontsize }: { theme: Theme; fontsize: string }) => ({
  [`&.${classes.blockquote}`]: {
    padding: 0,
    margin: 0,
  },

  [`& .${classes.blockquoteText}`]: {
    fontFamily: theme.typography.fontFamily,
    margin: 0,
    fontSize: '48px',
    lineHeight: 1.1667,
    [theme.breakpoints.up('md')]: {
      fontSize: fontsize ? fontsize : FontSize['6xl'],
      lineHeight: 1.5714,
      margin: 0,
    },
  },

  [`& .${classes.blockquoteTextCite}`]: {
    '&:before': {
      content: '"„"',
    },
    '&:after': {
      content: '"“"',
    },
  },

  [`& .${classes.blockquoteFooter}`]: {
    marginTop: theme.spacing(10),
    maxWidth: '336px',
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(14),
    },
  },

  [`& .${classes.blockquoteAuthor}`]: {
    display: 'block',
    fontFamily: theme.typography.fontFamily,
    fontSize: FontSize['2lg'],
    lineHeight: 1.25,
    fontStyle: 'normal',
  },

  [`& .${classes.blockquotePosition}`]: {
    display: 'block',
    marginTop: theme.spacing(2),
    fontFamily: theme.typography.fontFamily2,
    fontSize: FontSize['base'],
    lineHeight: 2,
    fontStyle: 'normal',
  },
}))

export type QuoteProps = {
  text: string
  author?: string
  position?: string
  className?: string
  fontsize?: string
}

export default function Blockquote({
  text,
  author,
  position,
  className,
  fontsize,
}: QuoteProps): ReactElement {
  return (
    <Root className={clsx(className, classes.blockquote)} fontsize={fontsize}>
      <p
        className={clsx(classes.blockquoteText, {
          [classes.blockquoteTextCite]: author,
        })}
      >
        {parse(
          text
            .replaceAll('  ', '<br>')
            .replaceAll('®', '<sup>®</sup>')
            .replaceAll('©', '<sup>©</sup>')
            .replaceAll('℗', '<sup>℗</sup>')
        )}
      </p>
      {author && (
        <footer className={classes.blockquoteFooter}>
          <cite className={classes.blockquoteAuthor}>{author}</cite>
          {position && (
            <span className={classes.blockquotePosition}>{position}</span>
          )}
        </footer>
      )}
    </Root>
  )
}
