import React, { ReactElement } from 'react'
import DefaultVideoVariant from './variants/default'
import Module from '@components/core/module'
import { Theme } from '@mui/material'

export type VideoProps = DBN.IReactDefaultProps & {
  theme?: Theme
  titleInternal?: string
  anchor?: string
  youtubeId?: string
  poster?: Array<DBN.Contentful.IAsset>
  posterMobile?: Array<DBN.Contentful.IAsset>
}

export default function Video({ theme, ...props }: VideoProps): ReactElement {
  return (
    <Module theme={theme} moduleTheme="dark" anchor={props.anchor}>
      <DefaultVideoVariant {...props} />
    </Module>
  )
}
