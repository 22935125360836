import React, { ReactElement } from 'react'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import { FormHelperText as HelperText } from '@mui/material'

import Icon from '@components/core/icon'

const PREFIX = 'helperText'

const classes = {
  helperTextRoot: `${PREFIX}-helperTextRoot`,
  spaced: `${PREFIX}-spaced`,
  icon: `${PREFIX}-icon`,
}

const StyledHelperText = styled(HelperText)(({ theme }) => ({
  [`&.${classes.helperTextRoot}`]: {
    color: theme.palette.text.input,
    margin: theme.spacing(1, 0),
    ...theme.typography.helpertext,
    '&.error': {
      color: '#e03a49',
    },
    '& a': {
      ...theme.typography.link,
    },
  },

  [`&.${classes.spaced}`]: {
    margin: theme.spacing(2, 4, 0, 0),
  },

  [`& .${classes.icon}`]: {
    marginRight: theme.spacing(2),
    color: theme.palette.text.input,
    verticalAlign: '-5px',
  },
}))

export type FormHelperTextProps = DBN.IReactDefaultProps & {
  spaced?: boolean
  type?: string
}

export default function FormHelperText({
  children,
  className,
  spaced,
  type,
}: FormHelperTextProps): ReactElement {
  return (
    <StyledHelperText
      className={clsx(className, classes.helperTextRoot, {
        error: type === 'error',
        [classes.spaced]: spaced,
      })}
    >
      {type === 'info' && <Icon className={classes.icon} name="Info" />}
      {children}
    </StyledHelperText>
  )
}
