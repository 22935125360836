import React, { ReactElement } from 'react'
import JobList from '@components/modules/content/M010-JobList'
import PressList from '@components/modules/content/M016-PressList'
import NewsList from '@components/modules/content/M012-NewsList'
import JobFixedApply from './types/jobfixedapply'
import { Theme } from '@mui/material'

export type FixedContentProps = DBN.IReactDefaultProps & {
  pageContext?: DBN.PageHelpers.PageContext
  theme?: Theme
  moduleTheme?: string
  anchor?: string
  element?: string
}

export default function FixedContent({
  pageContext,
  theme,
  moduleTheme,
  anchor,
  element,
  ...props
}: FixedContentProps): ReactElement {
  switch (element) {
    case 'joblist':
      return (
        <JobList
          pageContext={pageContext}
          theme={theme}
          moduleTheme={moduleTheme}
          anchor={anchor}
          {...props}
        />
      )
    case 'newslist':
      return (
        <NewsList
          pageContext={pageContext}
          theme={theme}
          moduleTheme={moduleTheme}
          anchor={anchor}
          {...props}
        />
      )
    case 'presslist':
      return (
        <PressList
          pageContext={pageContext}
          theme={theme}
          moduleTheme={moduleTheme}
          anchor={anchor}
          {...props}
        />
      )
    case 'jobfixedapply':
      return <JobFixedApply theme={theme} moduleTheme={moduleTheme} />
    default:
      return <></>
  }
}
