import React, { ReactElement } from 'react'
import { styled, Theme } from '@mui/material/styles'
import { InView } from 'react-intersection-observer'
import Container from '@components/modules/global/container'
import Copy from '@components/core/copy'
import Module from '@components/core/module'

const PREFIX = 'index'

const classes = {
  introRoot: `${PREFIX}-introRoot`,
  contentWrapper: `${PREFIX}-contentWrapper`,
  copyContainer: `${PREFIX}-copyContainer`,
  copy: `${PREFIX}-copy`,
}

const StyledModule = styled(Module)(({ theme }) => ({
  [`& .${classes.introRoot}`]: {
    marginBottom: 0,
  },

  [`& .${classes.contentWrapper}`]: {
    marginTop: theme.spacing(12),
    marginBottom: 0,
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(30),
    },
    '& > :nth-child(1)': {
      marginBottom: 0,
      opacity: 0,
      transform: 'translate(0, 10vh)',
      transition: 'opacity 0.6s, transform 0.6s ease-out',
    },
    '&.fade-in > :nth-child(1)': {
      transform: 'translate(0, 0)',
      opacity: 1,
    },
  },

  [`& .${classes.copyContainer}`]: {},

  [`& .${classes.copy}`]: {
    marginBottom: 0,
    ...theme.typography.h2,
    '& p': {
      marginBottom: 0,
    },
  },
}))

export type IntroProps = DBN.IReactDefaultProps & {
  titleInternal?: string
  theme?: Theme
  moduleTheme?: string
  anchor?: string
  copy?: DBN.Contentful.BasicRichTextType
}

export default function Intro({
  theme,
  moduleTheme,
  copy,
  anchor,
}: IntroProps): ReactElement {
  return (
    <InView threshold={0.4} triggerOnce={true}>
      {({ inView, ref }) => (
        <StyledModule
          theme={theme}
          moduleTheme={moduleTheme}
          anchor={anchor}
          className={classes.introRoot}
        >
          <div ref={ref}>
            <Container
              className={
                classes.contentWrapper +
                ' ' +
                (inView === true ? 'fade-in' : 'fade-out')
              }
            >
              {copy && <Copy className={classes.copy} richtext={copy} />}
            </Container>
          </div>
        </StyledModule>
      )}
    </InView>
  )
}
