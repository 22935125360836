import React, { ReactElement } from 'react'
import NotFound from '@components/modules/content/M014-NotFound'
import { useTheme } from '@mui/material/styles'

export interface ErrorPageProps {
  pageContext: DBN.PageHelpers.PageContext
}

export default function ErrorPage({
  pageContext,
}: ErrorPageProps): ReactElement {
  return <NotFound pageContext={pageContext} theme={useTheme()} />
}
