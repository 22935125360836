import React, { ReactElement, useMemo } from 'react'
import { styled } from '@mui/material/styles'
import { Theme, useMediaQuery } from '@mui/material'
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image'

const PREFIX = 'index'

const classes = {
  backgroundMediaRoot: `${PREFIX}-backgroundMediaRoot`,
  video: `${PREFIX}-video`,
  image: `${PREFIX}-image`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.backgroundMediaRoot}`]: {
    color: '#ffffff',
    height: '100vh',
    width: '100vw',
  },

  [`& .${classes.video}`]: {
    height: '100vh',
    width: '100vw',
    objectFit: 'cover',
    objectPosition: 'center',
  },

  [`& .${classes.image}`]: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    minWidth: '100%',
    minHeight: '100%',
    margin: '0 !important',
    zIndex: 0,
    '& .gatsby-image-wrapper': {
      height: '100vh',
    },
    [theme.breakpoints.up('lg')]: {
      '& .gatsby-image-wrapper': {
        height: '100vh',
      },
    },
  },
}))

export type BackgroundMediaProps = {
  className?: string
  media?: DBN.Contentful.IAsset
  mediaMobile?: DBN.Contentful.IAsset
  fallbackImage?: DBN.Contentful.IAsset
}

export default function BackgroundMedia({
  media,
  mediaMobile,
  fallbackImage,
}: BackgroundMediaProps): ReactElement {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))
  const isLarge = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))

  const type = useMemo(
    () =>
      isLarge
        ? media?.file?.contentType.split('/')[0]
        : mediaMobile?.file?.contentType.split('/')[0],
    [isLarge]
  )
  const poster = fallbackImage ? getSrc(fallbackImage) : ''

  const getBestImage = () => {
    if (mediaMobile?.file && isMobile === true) {
      return getImage(mediaMobile)
    } else if (media?.file) {
      return getImage(media)
    }
    return
  }

  return (
    <>
      {type === 'video' && media?.file && (
        <Root className={classes.backgroundMediaRoot}>
          {mediaMobile && mediaMobile?.file && isMobile === true && (
            <video
              className={classes.video}
              poster={poster}
              autoPlay={true}
              muted={true}
              loop={true}
              playsInline={true}
            >
              <source
                src={mediaMobile?.file.url}
                type={mediaMobile?.file.contentType}
              ></source>
            </video>
          )}
          {media && media?.file && isMobile === false && (
            <video
              className={classes.video}
              poster={poster}
              autoPlay
              muted
              loop
              playsInline
            >
              <source
                src={media?.file.url}
                type={media?.file.contentType}
              ></source>
            </video>
          )}
        </Root>
      )}
      {type === 'image' && getBestImage() && (
        <Root className={classes.backgroundMediaRoot}>
          <GatsbyImage
            className={classes.image}
            image={getBestImage()}
            alt={media?.description || ''}
            title={media?.title || ''}
            imgStyle={{
              objectFit: 'cover',
              objectPosition: 'top center',
            }}
            layout="fullWidth"
          />
        </Root>
      )}
    </>
  )
}
