import React, {
  ReactElement,
  useState,
  useEffect,
  useRef,
  Fragment,
} from 'react'
import { styled, Theme } from '@mui/material/styles'
import { graphql, useStaticQuery } from 'gatsby'
import clsx from 'clsx'
import { Grid } from '@mui/material'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import useGlobalText from '@system/hooks/useGlobalText'
import Container from '@components/modules/global/container'
import Headline from '@components/text/headline'
import Module from '@components/core/module'
import Copy from '@components/core/copy'
import Link from '@components/core/link'
import FontSize from '@config/theme/definitions/fontSize'

const PREFIX = 'index'

const classes = {
  newsListRoot: `${PREFIX}-newsListRoot`,
  newsListTeaser: `${PREFIX}-newsListTeaser`,
  newsListTeaserVisible: `${PREFIX}-newsListTeaserVisible`,
  newsListTeaserFadeIn: `${PREFIX}-newsListTeaserFadeIn`,
  newsTeaser: `${PREFIX}-newsTeaser`,
  newsTeaserImage: `${PREFIX}-newsTeaserImage`,
  newsTeaserContent: `${PREFIX}-newsTeaserContent`,
  newsTeaserHeadline: `${PREFIX}-newsTeaserHeadline`,
  newsTeaserCopy: `${PREFIX}-newsTeaserCopy`,
  newsTeaserLinkContainer: `${PREFIX}-newsTeaserLinkContainer`,
  newsTeaserLink: `${PREFIX}-newsTeaserLink`,
}

const StyledModule = styled(Module)(({ theme }) => ({
  [`&.${classes.newsListRoot}`]: {},

  [`& .${classes.newsListTeaser}`]: {
    opacity: 0,
  },

  [`& .${classes.newsListTeaserVisible}`]: {
    opacity: 1,
  },

  [`& .${classes.newsListTeaserFadeIn}`]: {
    animation: 'newsTeaserFadeIn 0.6s ease-out forwards',
  },

  '@keyframes newsTeaserFadeIn': {
    '0%': {
      opacity: 0,
      transform: 'translate(0, 10vh)',
    },
    '100%': {
      opacity: 1,
      transform: 'translate(0, 0)',
    },
  },

  [`& .${classes.newsTeaser}`]: {
    paddingBottom: theme.spacing(12),
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(17),
    },
    '&:not(:first-child)': {
      paddingTop: theme.spacing(20),
      [theme.breakpoints.up('md')]: {
        paddingBottom: theme.spacing(20),
      },
    },
  },

  [`& .${classes.newsTeaserImage}`]: {
    display: 'block !important',
  },

  [`& .${classes.newsTeaserContent}`]: {
    paddingTop: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(10),
    },
  },

  [`& .${classes.newsTeaserHeadline}`]: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(0),
    },
  },

  [`& .${classes.newsTeaserCopy}`]: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(4),
    },
    '& > *:last-child': {
      marginBottom: 0,
    },
  },

  [`& .${classes.newsTeaserLinkContainer}`]: {
    marginTop: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(6),
    },
    '& > *:last-child': {
      marginBottom: 0,
    },
  },

  [`& .${classes.newsTeaserLink}`]: {
    fontFamily: theme.typography.fontFamily,
    fontSize: FontSize['lg'],
    lineHeight: 1.11111,
  },
}))

interface INewsTeaser {
  node_locale?: string
  releaseDate?: string
  teaserTitle?: string
  teaserCopy?: DBN.Contentful.BasicRichTextType
  teaserLink?: DBN.Contentful.BasicRichTextType
  teaserImage?: DBN.Contentful.IAsset
  fields?: {
    fullPath: string
    isExternal: boolean
  }
}

export type NewsTeaserProps = DBN.IReactDefaultProps & INewsTeaser

export type NewsListProps = DBN.IReactDefaultProps & {
  pageContext?: DBN.PageHelpers.PageContext
  theme?: Theme
  moduleTheme?: string
  anchor?: string
}

export function NewsTeaser({
  teaserTitle,
  teaserCopy,
  teaserLink,
  teaserImage,
  fields,
  className,
  ...props
}: NewsTeaserProps): ReactElement {
  const { getText } = useGlobalText()

  return (
    <div className={clsx(classes.newsTeaser, className)} {...props}>
      <Grid
        container
        justifyContent="center"
        alignItems="flex-start"
        spacing={8}
      >
        <Grid item xs={12} sm={6}>
          {teaserImage && getImage(teaserImage) && (
            <GatsbyImage
              image={getImage(teaserImage)}
              alt={teaserImage.description || ''}
              title={teaserImage.title}
              imgStyle={{
                objectFit: 'cover',
                objectPosition: 'center center',
              }}
              className={classes.newsTeaserImage}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={classes.newsTeaserContent}>
            {teaserTitle && (
              <Headline className={classes.newsTeaserHeadline} level={2}>
                {teaserTitle}
              </Headline>
            )}
            {teaserCopy && (
              <Copy className={classes.newsTeaserCopy} richtext={teaserCopy} />
            )}
            {teaserLink && (
              <Copy
                className={classes.newsTeaserLinkContainer}
                richtext={teaserLink}
                type="teaser"
              />
            )}
            {fields?.fullPath && (
              <div className={classes.newsTeaserLinkContainer}>
                <Link
                  to={fields?.fullPath}
                  isExternal={fields?.isExternal}
                  className={classes.newsTeaserLink}
                >
                  {getText('module.newsList.linkLabel') || 'read more'}
                </Link>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default function NewsList({
  pageContext,
  theme,
  moduleTheme,
  anchor,
}: NewsListProps): ReactElement {
  const [page, setPage] = useState(1)
  const perPage = 4
  const triggerRef = useRef<HTMLDivElement>(null)

  const { allPressTeaser, allNewsTeaser } = useStaticQuery(graphql`
    query {
      allPressTeaser: allContentfulPressPage(
        sort: { fields: [releaseDate], order: DESC }
        filter: { url: { ne: null } }
      ) {
        nodes {
          id
          node_locale
          releaseDate
          teaserTitle
          teaserCopy {
            raw
          }
          teaserImage {
            gatsbyImageData(
              width: 520
              height: 520
              layout: FULL_WIDTH
              quality: 90
              placeholder: NONE
            )
            description
            title
          }
          fields {
            fullPath
            isExternal
          }
        }
      }
      allNewsTeaser: allContentfulNewsPage(
        sort: { fields: [releaseDate], order: DESC }
        filter: { url: { ne: null } }
      ) {
        nodes {
          id
          node_locale
          releaseDate
          teaserTitle
          teaserCopy {
            raw
          }
          teaserLink {
            raw
            references {
              ...ContentfulCasePageLinkFragment
              ...ContentfulGenericPageLinkFragment
              ...ContentfulPressPageLinkFragment
            }
          }
          teaserImage {
            gatsbyImageData(
              width: 520
              height: 520
              quality: 90
              placeholder: NONE
            )
            description
            title
          }
        }
      }
    }
  `)

  const teaser = [
    ...allPressTeaser.nodes.filter(
      (teaser: INewsTeaser) => teaser.node_locale == pageContext?.locale
    ),
    ...allNewsTeaser.nodes.filter(
      (teaser: INewsTeaser) => teaser.node_locale == pageContext?.locale
    ),
  ]

  const pageCount = Math.ceil(teaser.length / perPage)

  teaser.sort((a: INewsTeaser, b: INewsTeaser) => {
    const timeA = a.releaseDate ? Date.parse(a.releaseDate) : 0
    const timeB = b.releaseDate ? Date.parse(b.releaseDate) : 0
    return timeB - timeA
  })

  useEffect(() => {
    const handleScroll = () => {
      if (triggerRef.current) {
        const triggerRect = triggerRef.current.getBoundingClientRect()
        if (triggerRect.bottom - window.innerHeight < 0) {
          if (page < pageCount) {
            setPage(page + 1)
          } else {
            window.removeEventListener('scroll', handleScroll)
          }
        }
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [page, setPage, teaser])

  return (
    <StyledModule
      theme={theme}
      moduleTheme={moduleTheme}
      anchor={anchor}
      className={classes.newsListRoot}
    >
      <Container type="nomargin">
        <div ref={triggerRef}>
          {teaser.map((teaser: INewsTeaser, index: number) => {
            return index < page * perPage ? (
              <NewsTeaser
                key={index}
                teaserTitle={teaser.teaserTitle}
                teaserCopy={teaser.teaserCopy}
                teaserLink={teaser.teaserLink}
                teaserImage={teaser.teaserImage}
                fields={teaser.fields}
                className={clsx(classes.newsListTeaser, {
                  [classes.newsListTeaserVisible]: index < (page - 1) * perPage,
                  [classes.newsListTeaserFadeIn]: index >= (page - 1) * perPage,
                })}
              />
            ) : (
              <Fragment key={index}></Fragment>
            )
          })}
        </div>
      </Container>
    </StyledModule>
  )
}
