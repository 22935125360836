import React, { ReactElement, useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { Theme } from '@mui/material/styles'
import clsx from 'clsx'
import IntroVideo from '@static/img/intro/db-n_introvideo.mp4'
import IntroVideoMobile from '@static/img/intro/db-n_introvideo_mobile.mp4'
import { useMediaQuery } from '@mui/material'

const PREFIX = 'index'

const classes = {
  introAnimationRoot: `${PREFIX}-introAnimationRoot`,
  monogrammeVideo: `${PREFIX}-monogrammeVideo`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.introAnimationRoot}`]: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.secondary.main,
    transition: 'transform 0.3s ease-in',
    zIndex: 9999,
    '&.curton-off': {
      transition: 'transform 0.7s cubic-bezier(0.555, 0, 0.165, 1.000)',
      transformOrigin: 'left bottom',
      transform: 'translateY(-101vh)',
    },
    perspective: '200px',
  },

  [`& .${classes.monogrammeVideo}`]: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '100%',
    height: '100%',
    transform: 'translate(-50%, -50%)',
  },
}))

export type IIntroAnimationProps = {
  onBeforeComplete?: () => void
  onComplete?: () => void
}

export default function AnimationPros({
  onBeforeComplete,
  onComplete,
}: IIntroAnimationProps): ReactElement {
  const [introStatus, setIntroStatus] = useState('start')
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))

  useEffect(() => {
    let mounted = true
    if (mounted) {
      setTimeout(() => {
        setIntroStatus('step1')
      }, 1000)
      setTimeout(() => {
        setIntroStatus('step1 step2')
      }, 1600)
      setTimeout(() => {
        setIntroStatus('step1 step2 curton-off')
        if (onBeforeComplete) {
          onBeforeComplete()
        }
      }, 3500)
      setTimeout(() => {
        if (onComplete) {
          onComplete()
        }
      }, 4200)
    }
    return () => {
      mounted = false
    }
  }, [])

  return (
    <Root
      className={clsx(
        classes.introAnimationRoot,
        'intro-animation',
        introStatus
      )}
    >
      <video
        className={classes.monogrammeVideo}
        src={isMobile ? IntroVideoMobile : IntroVideo}
        muted
        autoPlay
        loop={false}
      />
    </Root>
  )
}
