import React, { ReactElement } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import { useField } from 'formik'
import FormHelperText from './helperText'

import clsx from 'clsx'
import closedIcon from '@mui/icons-material/Add'
import openedIcon from '@mui/icons-material/Remove'
import DeleteIcon from '@mui/icons-material/Close'

const PREFIX = 'selectBox'

const classes = {
  selectRoot: `${PREFIX}-selectRoot`,
  inputlabel: `${PREFIX}-inputlabel`,
  formControl: `${PREFIX}-formControl`,
  selectEmpty: `${PREFIX}-selectEmpty`,
  select: `${PREFIX}-select`,
  item: `${PREFIX}-item`,
  menuRoot: `${PREFIX}-menuRoot`,
  menuPaper: `${PREFIX}-menuPaper`,
  deleteButton: `${PREFIX}-deleteButton`,
  errorField: `${PREFIX}-errorField`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.selectRoot}`]: {
    cursor: 'pointer',
    marginBottom: theme.spacing(8),
    '& .MuiSelect-select': {
      paddingRight: theme.spacing(12),
    },
    '& svg': {
      color: 'inherit',
      right: theme.spacing(2),
    },
    '& .MuiSelect-select:focus': {
      background: 'none',
    },
    '& .MuiInput-underline:after': {
      transform: 'scaleX(1)',
    },
  },

  [`& .${classes.inputlabel}`]: {
    color: '#A7B3BE',
    top: theme.spacing(2.5),
    left: theme.spacing(0),
    '&.Mui-focused,&.MuiFormLabel-filled': {
      top: theme.spacing(0),
      left: theme.spacing(0),
      color: '#A7B3BE',
    },
  },

  [`& .${classes.formControl}`]: {
    // margin: theme.spacing(1),
    minWidth: 220,
    width: '100%',
    '& .MuiInput-underline:after': {
      borderColor: '#6C7883',
    },
    '& .Mui-focused.MuiInput-underline:after': {
      borderColor: theme.palette.text.input,
    },
  },

  [`& .${classes.selectEmpty}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.select}`]: {
    minWidth: '120px',
    width: '100%',
    maxWidth: '520px',
    padding: theme.spacing(3, 0),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(2, 0),
    },
    height: '48px',
    boxSizing: 'border-box',
    borderRadius: 0,
    appearance: 'none',
    ...theme.typography.input,
  },

  [`& .${classes.menuRoot}`]: {
    marginLeft: theme.spacing(0),
    minWidth: 'auto',
    '& input': {
      display: 'none',
    },
  },

  [`& .${classes.menuPaper}`]: {
    maxWidth: '100%',
  },

  [`& .${classes.deleteButton}`]: {
    position: 'absolute',
    right: '8px',
    top: '23px',
    width: '24px',
    height: '24px',
    zIndex: 0,
    color: 'inherit',
  },

  [`& .${classes.errorField}`]: {
    marginLeft: theme.spacing(1),
  },
}))

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  [`&.${classes.item}`]: {
    backgroundColor: '#0000db',
    color: theme.palette.primary.light,
    '&.Mui-selected, &.Mui-selected:hover, &.MuiMenuItem-root:hover': {
      background: theme.palette.background.default,
      color: theme.palette.primary.main,
    },
    '&:first-child': {
      display: 'none',
    },
  },
}))

export type ISelectBoxProps = {
  id?: string
  className?: string
  label?: string
  infoText?: string
  placeholder?: string
  onChange?: null
  items?: Array<ISelectItem>
  initialValue?: string
  defaultValue?: string
}

export type ISelectItem = {
  text?: string
  value?: string | number
}
export default function SelectBox(props: ISelectBoxProps): ReactElement {
  const {
    id,
    className,
    label,
    placeholder,
    onChange,
    items,
    initialValue = '',
    defaultValue = '',
  } = props

  const [open, setOpen] = React.useState(false)
  const [val, setValue] = React.useState(initialValue || defaultValue)

  const meta = null
  const field = null

  if (props.name) {
    const [field, meta] = useField(props)
  }

  const menuProps = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
  } as const

  const handleChange = (event: SelectChangeEvent<{ value: unknown }>) => {
    setValue(event.target.value as string)
    onChange(event.target.value as string)
  }

  const getIcon = () => {
    if (open === true) {
      return openedIcon
    } else {
      if (val) {
        return DeleteIcon
      } else {
        return closedIcon
      }
    }
  }

  return (
    <Root className={clsx(classes.selectRoot, className)} theme={useTheme()}>
      <FormControl variant="standard" className={clsx(classes.formControl)}>
        {label && (
          <InputLabel
            className={classes.inputlabel}
            id={`${id}-label`}
            htmlFor={id}
          >
            {label}
          </InputLabel>
        )}
        <Select
          variant="standard"
          id={id}
          labelId={`${id}-label`}
          className={classes.select}
          value={val}
          onChange={handleChange}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          MenuProps={menuProps}
          IconComponent={getIcon()}
          classes={{
            root: classes.menuRoot,
          }}
        >
          <StyledMenuItem value="" className={classes.item}>
            <em>{placeholder}</em>
          </StyledMenuItem>

          {items?.map((member: ISelectItem, idx: number) => (
            <StyledMenuItem
              className={classes.item}
              value={member.value}
              key={`${id}-select-item${idx}`}
            >
              {member.text}
            </StyledMenuItem>
          ))}
        </Select>
        {val && val.length > 0 && (
          <a
            className={classes.deleteButton}
            onClick={(e) => {
              setValue(defaultValue)
              onChange(defaultValue)
            }}
          />
        )}
      </FormControl>
      {meta && meta.touched && meta.error ? (
        <div className={classes.errorField}>
          <FormHelperText spaced type="error">
            {meta.error}
          </FormHelperText>
        </div>
      ) : null}
    </Root>
  )
}
