import React, { ReactElement } from 'react'
import { styled, Theme } from '@mui/material/styles'
import clsx from 'clsx'
import { InView } from 'react-intersection-observer'
import Container from '@components/modules/global/container'
import Module from '@components/core/module'

const PREFIX = 'index'

const classes = {
  textColumnRoot: `${PREFIX}-textColumnRoot`,
  textColumnWrapper: `${PREFIX}-textColumnWrapper`,
  textColumnWrapperFadeIn: `${PREFIX}-textColumnWrapperFadeIn`,
}

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const StyledModule = styled(Module)(({ theme }) => ({
  [`& .${classes.textColumnRoot}`]: {
    padding: theme.spacing(12, 0),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(30, 0),
    },
  },

  [`& .${classes.textColumnWrapper}`]: {
    opacity: 0,
    transform: 'translate(0, 10vh)',
    transition: 'transform 0.6s ease-out',
  },

  [`& .${classes.textColumnWrapperFadeIn}`]: {
    opacity: 1,
    transform: 'translate(0, 0)',
  },
}))

export type TextColumnProps = DBN.IReactDefaultProps & {
  theme?: Theme
  moduleTheme?: string
}

export default function TextColumn({
  theme,
  moduleTheme,
  children,
  ...props
}: TextColumnProps): ReactElement {
  return (
    <>
      <InView threshold={0} triggerOnce={true}>
        {({ inView, ref }) => (
          <StyledModule
            theme={theme}
            moduleTheme={moduleTheme}
            className={classes.textColumnRoot}
            {...props}
          >
            <div ref={ref}>
              <Container
                type="nomargin"
                className={clsx(classes.textColumnWrapper, {
                  [classes.textColumnWrapperFadeIn]: inView,
                })}
              >
                {children}
              </Container>
            </div>
          </StyledModule>
        )}
      </InView>
    </>
  )
}
