import React, { ReactElement, useEffect, useState, useRef } from 'react'
import { styled } from '@mui/material/styles'
import Container from '@components/modules/global/container'
import { Grid, Theme } from '@mui/material'
import clsx from 'clsx'
import Headline from '@components/text/headline'
import Module from '@components/core/module'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'

const PREFIX = 'index'

const classes = {
  awardsRoot: `${PREFIX}-awardsRoot`,
  awardsContainer: `${PREFIX}-awardsContainer`,
  scrollList: `${PREFIX}-scrollList`,
  scrollListItem: `${PREFIX}-scrollListItem`,
  gridItem: `${PREFIX}-gridItem`,
  scrollListItemWrapper: `${PREFIX}-scrollListItemWrapper`,
  scrollListItemTitle: `${PREFIX}-scrollListItemTitle`,
  scrollListItemImage: `${PREFIX}-scrollListItemImage`,
}

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.awardsRoot}`]: {
    marginTop: theme.spacing(0),
    marginBottom: '0px',
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(4),
  },

  [`& .${classes.awardsContainer}`]: {
    width: '100vw',
  },

  [`& .${classes.scrollList}`]: {
    position: 'relative',
  },

  [`& .${classes.scrollListItem}`]: {
    width: '100%',
    position: 'absolute',
    '&:first-child': {
      position: 'relative',
      '& h2': {
        marginBottom: theme.spacing(8),
      },
    },
    '& h2': {
      marginBottom: 0,
    },
    top: 0,
    opacity: 0,
    '&.active': {
      opacity: 1,
    },
  },

  [`& .${classes.gridItem}`]: {
    display: 'flex',
    maxHeight: '400px',
    minHeight: '256px',
    alignItems: 'center',
  },

  [`& .${classes.scrollListItemWrapper}`]: {
    height: '100%',
  },

  [`& .${classes.scrollListItemTitle}`]: {},

  [`& .${classes.scrollListItemImage}`]: {
    maxHeight: '100%',
    height: '256px',
  },
}))

export type AwardsProps = DBN.IReactDefaultProps & {
  titleInternal?: string
  moduleTheme?: string
  theme?: Theme
  anchor?: string
  awardElement?: Array<DBN.Contentful.IAwardElement>
}

export default function Awards({
  moduleTheme,
  theme,
  anchor,
  awardElement,
}: AwardsProps): ReactElement {
  const containerRef = useRef<HTMLDivElement>(null)

  const [activeItem, setActiveItem] = useState(0)

  useEffect(() => {
    function handleScroll() {
      const modCnt = awardElement?.length || 0
      if (modCnt === 0) {
        setActiveItem(0)
        return
      }

      if (containerRef.current) {
        const containerRect = containerRef.current?.getBoundingClientRect()

        const start = containerRect.top - window.innerHeight
        const stop = containerRect.bottom

        if (start < 0 && 0 < stop) {
          const distance =
            (window.innerHeight - containerRect.height * 0.5) / modCnt
          const offset = containerRect.height * 0.75
          const position = window.innerHeight - containerRect.top
          const va = Math.floor((position - offset) / distance)
          setActiveItem(Math.max(Math.min(va, modCnt - 1), 0))
        }
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <Root ref={containerRef}>
      <Module
        theme={theme}
        moduleTheme={moduleTheme}
        anchor={anchor}
        className={classes.awardsRoot}
      >
        <div className={classes.awardsContainer}>
          <div className={classes.scrollList}>
            {awardElement?.map(
              (award: DBN.Contentful.IAwardElement, i: number) => (
                <div
                  type="nomargin"
                  className={clsx(classes.scrollListItem, {
                    active: i === activeItem,
                  })}
                  key={i}
                >
                  <Container type="nomargin">
                    <Grid
                      container
                      className={classes.scrollListItemWrapper}
                      justifyContent="space-between"
                      alignContent="flex-start"
                      alignItems="center"
                      spacing={8}
                    >
                      <Grid item xs={12} md={6} className={classes.gridItem}>
                        <Headline
                          className={classes.scrollListItemTitle}
                          level={21}
                        >
                          {award.headline}
                        </Headline>
                      </Grid>
                      <Grid item xs={12} md={6} className={classes.gridItem}>
                        {award.image && (
                          <GatsbyImage
                            className={classes.scrollListItemImage}
                            image={getImage(award.image) as IGatsbyImageData}
                            alt={award.image.description || ''}
                            title={award.image.title}
                            imgStyle={{
                              objectFit: 'contain',
                              objectPosition: 'center center',
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Container>
                </div>
              )
            )}
          </div>
        </div>
      </Module>
    </Root>
  )
}
