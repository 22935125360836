import React, { ReactElement } from 'react'
import { styled } from '@mui/material/styles'
import { useField } from 'formik'
import FormHelperText from './helperText'
import TextInput from './textInput'
import clsx from 'clsx'

const PREFIX = 'textField'

const classes = {
  formGroup: `${PREFIX}-formGroup`,
  inputlabel: `${PREFIX}-inputlabel`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.formGroup}`]: {
    '& .input-date input.MuiInputBase-input': {
      color: '#FF050a00',
    },
    '& .input-date .Mui-focused input.MuiInputBase-input': {
      color: theme.palette.text.input,
    },
    '&.filled .input-date input.MuiInputBase-input': {
      color: theme.palette.text.input,
    },
    marginBottom: theme.spacing(8),
    width: '100%',
    '& .input': {
      width: '100%',
      maxWidth: '520px',
    },
    '&.error label': {
      color: '#e03a49',
    },
    '&.error .MuiInputBase-root .MuiInput-input': {
      borderColor: '#e03a49',
    },

    '& .MuiInputLabel-formControl': {
      color: '#A7B3BE',
      top: theme.spacing(2.5),
      left: theme.spacing(0),
      '&.Mui-focused,&.MuiFormLabel-filled': {
        top: theme.spacing(0),
        left: theme.spacing(0),
        color: theme.palette.text.input + '!important',
      },
    },
  },

  [`& .${classes.inputlabel}`]: {
    ...theme.typography.label,
    padding: theme.spacing(0, 5, 1, 0),
    color: 'inherit',
    width: '100%',
  },
}))

export type TextFieldProps = {
  label: string
  infoText?: string | undefined
  id: string
  name: string
  type: string
  variant?: string | undefined
  placeholder?: string | undefined
  autoComplete?: string | undefined
}

export default function TextField(props: TextFieldProps): ReactElement {
  const { label, infoText, id, name, type, ...other } = props

  const [field, meta] = useField(name)

  return (
    <Root
      className={clsx(classes.formGroup, {
        error: meta.touched && meta.error,
        filled: field.value,
      })}
    >
      <TextInput
        id={id}
        className={clsx('input', `input-${type}`)}
        label={label}
        type={type}
        error={meta.touched && meta.error}
        {...field}
        {...other}
      />
      {infoText && (
        <FormHelperText spaced type="info">
          {infoText}
        </FormHelperText>
      )}
      {meta.touched && meta.error ? (
        <FormHelperText spaced type="error">
          {meta.error}
        </FormHelperText>
      ) : null}
    </Root>
  )
}
