import React, { ReactElement } from 'react'

import Typography from '@mui/material/Typography'

export type ListProps = DBN.IReactDefaultProps

export default function OrderedList({
  children,
  ...props
}: ListProps): ReactElement {
  return (
    <ol {...props}>
      {(() => {
        return React.Children.map(children, (child, index) => {
          return (
            <Typography component="li" key={index}>
              {child}
            </Typography>
          )
        })
      })()}
    </ol>
  )
}
