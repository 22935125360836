import React, { ReactElement } from 'react'
import { styled } from '@mui/material/styles'
const PREFIX = 'index'

const classes = {
  separatorRoot: `${PREFIX}-separatorRoot`,
}

const Root = styled('hr')(({ theme }) => ({
  [`&.${classes.separatorRoot}`]: {
    margin: theme.spacing(10, 0, 10, 0),
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(15, 0, 15, 0),
    },
  },
}))

export default function Separator(): ReactElement {
  return <Root className={classes.separatorRoot} />
}
