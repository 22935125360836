import React, { ReactElement, useState, useEffect, useMemo } from 'react'
import { styled, Theme } from '@mui/material/styles'
import clsx from 'clsx'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { Grid } from '@mui/material'
import useCustomCursor from '@system/hooks/useCustomCursor'
import { InView } from 'react-intersection-observer'

import Headline from '@components/text/headline'
import Copy from '@components/core/copy'
import Container from '@components/modules/global/container'
import Module from '@components/core/module'
import PageLink from '@components/core/pagelink'
import Carousel from '@components/core/carousel'

const PREFIX = 'index'

const classes = {
  teaserTextRoot: `${PREFIX}-teaserTextRoot`,
  teaserTextHeadline: `${PREFIX}-teaserTextHeadline`,
  teaserTextHeadlineSlider: `${PREFIX}-teaserTextHeadlineSlider`,
  teaserTextGrid: `${PREFIX}-teaserTextGrid`,
  teaserTextGridItem: `${PREFIX}-teaserTextGridItem`,
  teaserTextSlider: `${PREFIX}-teaserTextSlider`,
  teaserTextSlide: `${PREFIX}-teaserTextSlide`,
  teaserTextItem: `${PREFIX}-teaserTextItem`,
  teaserTextItemImage: `${PREFIX}-teaserTextItemImage`,
  teaserTextItemDescription: `${PREFIX}-teaserTextItemDescription`,
  centered: `${PREFIX}-centered`,
  center: `${PREFIX}-center`,
  teaserTextItemHeadline: `${PREFIX}-teaserTextItemHeadline`,
  teaserTextItemCopy: `${PREFIX}-teaserTextItemCopy`,
  teaserTextItemFadeIn: `${PREFIX}-teaserTextItemFadeIn`,
}

const StyledModule = styled(Module)(({ theme }) => ({
  [`&.${classes.teaserTextRoot}`]: {
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(20),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(30),
      paddingBottom: theme.spacing(30),
    },
  },

  [`& .${classes.teaserTextHeadline}`]: {
    marginBottom: theme.spacing(8),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(10),
    },
  },

  [`& .${classes.teaserTextHeadlineSlider}`]: {
    marginBottom: theme.spacing(16),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(10),
    },
  },

  [`& .${classes.teaserTextGrid}`]: {
    margin: theme.spacing(-4, 0),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(-6, 0),
    },
  },

  [`& .${classes.teaserTextGridItem}`]: {
    padding: theme.spacing(4, 0),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6, 0),
    },
  },

  [`& .${classes.teaserTextSlider}`]: {
    overflow: 'visible !important',
  },

  [`& .${classes.teaserTextSlide}`]: {
    paddingRight: theme.spacing(4),
  },

  [`& .${classes.teaserTextItem}`]: {
    display: 'block',
    transform: 'translate(0, 10vh)',
    transition: 'transform 0.6s ease-in',
    [`&:hover .${classes.teaserTextItemImage}`]: {
      transform: 'scale(1.1)',
      transition: 'transform 0.6s ease-in',
    },
  },

  [`& .${classes.teaserTextItemImage}`]: {
    pointerEvents: 'none',
    marginBottom: theme.spacing(4),
    transition: 'transform 0.6s ease-out',
    '& img': {
      maxHeight: '80px',
    },
  },

  [`& .${classes.teaserTextItemDescription}`]: {
    opacity: 0,
    transition: 'opacity 0.6s',
  },

  [`& .${classes.centered}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  [`& .${classes.center}`]: {
    textAlign: 'center',
  },

  [`& .${classes.teaserTextItemHeadline}`]: {
    marginTop: 0,
    marginBottom: theme.spacing(4),
  },

  [`& .${classes.teaserTextItemCopy}`]: {
    marginTop: 0,
    marginBottom: theme.spacing(3),
    '& > *:last-child': {
      marginBottom: 0,
    },
  },

  [`& .${classes.teaserTextItemFadeIn}`]: {
    transform: 'translate(0, 0)',
    transition: 'transform 0.6s ease-out',
    [`& .${classes.teaserTextItemDescription}`]: {
      opacity: 1,
    },
  },
}))

export type TeaserTextPageProps = {
  teaserTitle?: string
  teaserCopy?: DBN.Contentful.BasicRichTextType
  teaserImage?: DBN.Contentful.IAsset
  fields: {
    fullPath: string
    isExternal: boolean
  }
}

export type TeaserTextProps = DBN.IReactDefaultProps & {
  theme?: Theme
  moduleTheme?: string
  anchor?: string
  slider?: boolean
  headline?: string
  pages?: Array<TeaserTextPageProps>
}

export type TeaserTextItemProps = DBN.IReactDefaultProps & {
  page: TeaserTextPageProps
}

export function TeaserTextItem({ page }: TeaserTextItemProps): ReactElement {
  const { setCursorType } = useCustomCursor()
  const gatsbyImage = page.teaserImage ? getImage(page.teaserImage) : null
  const cursor = useMemo(
    () =>
      page.fields
        ? page.fields?.isExternal
          ? 'teaserExternal'
          : 'teaserMore'
        : '',
    [page.fields?.isExternal]
  )

  return (
    <InView threshold={0} triggerOnce={true} delay={100}>
      {({ inView, ref }) => (
        <div ref={ref}>
          <PageLink
            page={page}
            className={clsx(classes.teaserTextItem, {
              [classes.teaserTextItemFadeIn]: inView,
            })}
          >
            <div
              className={clsx({
                [classes.centered]: page.teaserImage,
              })}
              onMouseEnter={() => setCursorType(cursor)}
              onMouseLeave={() => setCursorType('')}
              onClick={() => setCursorType('')}
            >
              {page.teaserImage && gatsbyImage && (
                <GatsbyImage
                  image={gatsbyImage}
                  alt={page.teaserImage.description || ''}
                  title={page.teaserImage.title}
                  className={classes.teaserTextItemImage}
                  objectFit="contain"
                  objectPosition="center"
                  imgStyle={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    width: 'auto',
                    height: 'auto',
                    marginTop: 'auto',
                    marginRight: 'auto',
                  }}
                />
              )}
              <div
                className={clsx(classes.teaserTextItemDescription, {
                  [classes.center]: page.teaserImage,
                })}
              >
                {page.teaserTitle && (
                  <Headline
                    level={3}
                    className={classes.teaserTextItemHeadline}
                  >
                    {page.teaserTitle}
                  </Headline>
                )}
                {page.teaserCopy && (
                  <Copy
                    richtext={page.teaserCopy}
                    className={classes.teaserTextItemCopy}
                  />
                )}
              </div>
            </div>
          </PageLink>
        </div>
      )}
    </InView>
  )
}

export function TeaserTextMobile({ pages }: TeaserTextProps): ReactElement {
  return (
    <div className={classes.teaserTextGrid}>
      <Grid container spacing={8}>
        {pages?.map((page: TeaserTextPageProps, index: number) => (
          <Grid item key={index} xs={12}>
            <div className={classes.teaserTextGridItem}>
              <TeaserTextItem page={page} />
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

export function TeaserTextMobileSlider({
  pages,
}: TeaserTextProps): ReactElement {
  return (
    <Carousel className={classes.teaserTextSlider}>
      {pages?.map((page: TeaserTextPageProps, index: number) => (
        <div className={classes.teaserTextSlide} key={index}>
          <TeaserTextItem page={page} />
        </div>
      ))}
    </Carousel>
  )
}

export function TeaserTextDesktop({ pages }: TeaserTextProps): ReactElement {
  return (
    <div className={classes.teaserTextGrid}>
      <Grid container spacing={8}>
        {pages?.map((page: TeaserTextPageProps, index: number) => (
          <Grid item key={index} md={4}>
            <div className={classes.teaserTextGridItem}>
              <TeaserTextItem page={page} />
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

export default function TeaserText({
  theme,
  moduleTheme,
  anchor,
  slider,
  headline,
  pages,
}: TeaserTextProps): ReactElement {
  const [mobile, setMobile] = useState(true)

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 768px)')

    function mediaHandler(this: MediaQueryList) {
      if (this.matches) {
        setMobile(false)
      } else {
        setMobile(true)
      }
    }

    if (mediaQuery.matches) {
      setMobile(false)
    }

    mediaQuery.addListener(mediaHandler)
    return () => {
      mediaQuery.removeListener(mediaHandler)
    }
  })

  return pages && pages.length ? (
    <StyledModule
      theme={theme}
      moduleTheme={moduleTheme}
      anchor={anchor}
      className={classes.teaserTextRoot}
    >
      <Container type="nomargin">
        {headline && (
          <Headline
            level={21}
            className={clsx(classes.teaserTextHeadline, {
              [classes.teaserTextHeadlineSlider]: slider,
            })}
          >
            {headline}
          </Headline>
        )}
        {mobile ? (
          slider ? (
            <TeaserTextMobileSlider pages={pages} />
          ) : (
            <TeaserTextMobile pages={pages} />
          )
        ) : (
          <TeaserTextDesktop pages={pages} />
        )}
      </Container>
    </StyledModule>
  ) : (
    <></>
  )
}
